import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { Link as RouterLink, useParams } from "react-router-dom";
import { endOfDay, format } from "date-fns";

import {
  Add,
  Adjust as AdjustIcon,
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
  CancelPresentation as CancelPresentationIcon,
  Clear,
  EmojiEvents as EmojiEventsIcon,
  ExitToApp,
  ExpandMore as ExpandMoreIcon,
  Event as EventIcon,
  FiberNew as FiberNewIcon,
  Lens,
  MoreVert,
  TimerOff as TimerOffIcon,
} from "@material-ui/icons";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from "@material-ui/core";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
} from "@material-ui/lab";

import Product from "../../components/Product";
import { useBmapi } from "../../utils/bmapi-context";
import { getLabel, IconsMap, getAvailability } from "../../utils/campaigns";
import styles from "../../utils/styles";
import {
  CONSUMER_ROUTES,
  PERMISSION_TYPES,
  PRODUCTS,
  PRODUCT_SUBTYPES,
  PRODUCT_TYPES,
  TENANTS,
  TX_DESCRIPTIONS,
  TX_TYPES,
} from "../../utils/constants";
import ProductDetails from "../../ui/ProductDetails";
import { getErrorMessageString } from "../../utils/errors";
import {
  campaign as campaignMsg,
  common,
  product as productMsg,
} from "../../messages";
import { getShareUrl } from "../../ui/Product";

function toMerge(tx1, tx2) {
  return (
    tx1.business_name === tx2.business_name &&
    tx1.description === tx2.description &&
    tx1.type === tx2.type &&
    ![TX_TYPES.DECREASE, TX_TYPES.INCREASE].includes(tx1.type) &&
    tx1.timestamp.slice(0, 16) === tx2.timestamp.slice(0, 16)
  );
}

function mergeTxs(acc, tx) {
  if (acc.length === 0) {
    return [tx];
  }
  const last = acc[acc.length - 1];
  return toMerge(last, tx)
    ? [
        ...acc.slice(0, -1),
        { ...last, counter: last.counter ? last.counter + 1 : 2 },
      ]
    : [...acc, tx];
}

function getDetails({ campaign, statistics }, availability) {
  switch (campaign.type) {
    case PRODUCT_TYPES.CAMPAIGN_EARNING_CARD:
      switch (campaign.rules.subtype) {
        case PRODUCT_SUBTYPES.EARNING_CARD_INSTANT_WIN:
          return [
            {
              label: campaignMsg.receivedPoints,
              value: statistics.received_value / 100,
            },
            {
              label: campaignMsg.usedPlays,
              value:
                statistics.used_value / campaign.rules.instant_win_threshold,
            },
            {
              label: campaignMsg.availablePlays,
              value: availability,
            },
          ];

        default:
          return [
            {
              label: campaignMsg.balance,
              value: `${availability} ${campaign.rules.currency}`,
            },
            {
              label: campaignMsg.receivedPoints,
              value: `${statistics.received_value / 100} ${
                campaign.rules.currency
              }`,
            },
            {
              label: campaignMsg.usedPoints,
              value: `${statistics.used_value / 100} ${
                campaign.rules.currency
              }`,
            },
            {
              label: campaignMsg.expiredPoints,
              value: `${statistics.expired_value / 100} ${
                campaign.rules.currency
              }`,
            },
          ];
      }

    case PRODUCT_TYPES.CAMPAIGN_SHOPPING_CARD:
      return [
        {
          label: campaignMsg.receivedCards,
          value: statistics.received_qty,
        },
        {
          label: campaignMsg.remainingValue,
          value: `${availability} ${campaign.rules.currency}`,
        },
        {
          label: campaignMsg.usedValue,
          value: `${statistics.used_value / 100} ${campaign.rules.currency}`,
        },
        {
          label: campaignMsg.sharedValue,
          value: `${statistics.issued_value / 100} ${campaign.rules.currency}`,
        },
        {
          label: campaignMsg.expiredValue,
          value: `${statistics.expired_value / 100} ${campaign.rules.currency}`,
        },
      ];
    case PRODUCT_TYPES.CAMPAIGN_COUPON:
      return [
        {
          label: campaignMsg.availablesCoupons,
          value: availability,
        },
        {
          label: campaignMsg.receivedCoupons,
          value: statistics.received_qty,
        },
        {
          label: campaignMsg.usedCoupons,
          value: statistics.used_qty,
        },
        {
          label: campaignMsg.sharedCoupons,
          value: statistics.issued_qty,
        },
        {
          label: campaignMsg.expiredCoupons,
          value: statistics.expired_qty,
        },
      ];

    case PRODUCT_TYPES.CAMPAIGN_EVENT_PASS:
      return [
        {
          label: campaignMsg.availablePasses,
          value: availability,
        },
        {
          label: campaignMsg.receivedPasses,
          value: statistics.received_qty,
        },
        {
          label: campaignMsg.usedPasses,
          value: statistics.used_qty,
        },
        {
          label: campaignMsg.sharedPasses,
          value: statistics.issued_qty,
        },
        {
          label: campaignMsg.expiredPasses,
          value: statistics.expired_qty,
        },
      ];

    default:
      break;
  }

  return [];
}

function ProductCard({ action, title, subtitle, Icon, iconBg, counter }) {
  const classes = styles.useStyles();

  return (
    <ListItem>
      {Icon && (
        <ListItemAvatar>
          <Badge
            color="secondary"
            badgeContent={counter > 1 ? counter : 0}
            overlap="circle"
          >
            <Avatar
              className={classes.cardIcon}
              style={iconBg && { backgroundColor: iconBg }}
            >
              <Icon />
            </Avatar>
          </Badge>
        </ListItemAvatar>
      )}
      <ListItemText primary={title} secondary={subtitle} />
      {action && (
        <ListItemSecondaryAction>
          <IconButton edge="end" onClick={action.fn}>
            <action.Icon />
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
}

function SharedProduct({ campaign, permission, product, onUpdate }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const intl = useIntl();
  const {
    baseUrl,
    bmapi,
    notifySuccess,
    startLoading,
    stopLoading,
  } = useBmapi();

  const shareUrl = getShareUrl(baseUrl, permission?.short_code);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const copy = useCallback(() => {
    return navigator.clipboard?.writeText(shareUrl).then(() =>
      notifySuccess(
        intl.formatMessage({
          id: "component.friend.urlCopied",
          defaultMessage: "Link copiato",
        })
      )
    );
  }, [intl, notifySuccess, shareUrl]);

  const share = () => {
    handleClose();

    return navigator.share
      ? navigator.share({ url: shareUrl }).catch(copy)
      : copy();
  };

  const stopShare = () => {
    handleClose();
    startLoading();
    bmapi
      .disableProductPermissions(permission.id)
      .then(onUpdate)
      .finally(stopLoading);
  };

  return (
    <>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={share}>
          {intl.formatMessage({
            id: "component.product.donate",
            defaultMessage: "Condividi",
          })}
        </MenuItem>
        <MenuItem onClick={stopShare}>
          {intl.formatMessage({
            id: "component.product.stopDonate",
            defaultMessage: "Annulla condivisione",
          })}
        </MenuItem>
      </Menu>
      <ProductCard
        key={product.id}
        title={intl.formatMessage(
          productMsg[getLabel(campaign.rules.subtype)].shared,
          { counter: 1 }
        )}
        subtitle={
          permission
            ? intl.formatMessage(campaignMsg.sharingExpiration, {
                expirationDate: new Date(permission?.expire_at),
              })
            : ""
        }
        Icon={IconsMap[product.subtype]}
        action={{
          Icon: MoreVert,
          fn: handleClick,
        }}
      />
    </>
  );
}

export default function Campaign() {
  const { campaignId } = useParams();
  const intl = useIntl();
  const theme = useTheme();
  const {
    bmapi,
    userId,
    startLoading,
    stopLoading,
    notifySuccess,
    notifyError,
  } = useBmapi();
  const [campaign, setCampaign] = useState(null);
  const [stats, setStats] = useState(null);
  const [products, setProducts] = useState(null);
  const [productsPermissions, setProductsPermissions] = useState({});
  const [businesses, setBusinesses] = useState([]);
  const [prizes, setPrizes] = useState(null);
  const [transactions, setTransactions] = useState(null);
  const [viewStores, setViewStores] = useState(false);
  const [stores, setStores] = useState(false);
  const [reserved, setReserved] = useState(false);
  const [issuedQuantity, setIssuedQuantity] = useState(false);
  const [viewTransactions, setViewTransactions] = useState(false);
  const [checkins, setCheckins] = useState(false);
  const [lesson, setLesson] = useState(false);

  const updateTransactions = useCallback(() => {
    if (!viewTransactions) {
      bmapi.getTransactionsByCampaign(campaignId).then((transactions) => {
        setTransactions(transactions || []);
      });
    }
  }, [bmapi, campaignId, viewTransactions]);

  const handleOpenTransactions = (f) => {
    if (f && !transactions) {
      updateTransactions();
    }
    setViewTransactions(f);
  };

  const handleCloseStores = () => setViewStores(false);

  const byExpiration = (a, b) =>
    new Date(a.expiration_date) - new Date(b.expiration_date);

  const updateCheckins = useCallback(() => {
    bmapi.getUserCheckIns().then((cs) => {
      const campCheckins = (cs || [])
        .filter((c) => (c.campaign_ids || []).includes(campaignId))
        .sort((a, b) => new Date(b.check_in_at) - new Date(a.check_in_at));
      setCheckins(campCheckins.filter((c) => c.checked_out));
      setLesson(campCheckins.find((c) => !c.checked_out));
    });
  }, [bmapi, campaignId]);

  const updateData = useCallback(() => {
    startLoading();

    // setTransactions(false);
    // updateTransactions();

    Promise.all([
      bmapi.getCampaign(campaignId),
      userId ? bmapi.getUserProductsStats() : [],
      userId ? bmapi.getUserProductsByCampaign(campaignId) : [],
      userId ? bmapi.getUserReservations() : [],
    ])
      .then(([campaign, stats, products, reservations]) => {
        setCampaign(campaign.campaign);
        setIssuedQuantity(campaign.issued_qty);
        setStats(stats.find((s) => s.campaign.id === campaignId) || false);
        setProducts(products.sort(byExpiration) || []);

        products
          .filter((p) => p.status === 4)
          .map((p) =>
            bmapi.getProductPermissions(p.id).then((ps) =>
              setProductsPermissions((perms) => ({
                ...perms,
                [p.id]: (ps || []).filter(
                  (p) => p.type === PERMISSION_TYPES.SEND_PERMISSION
                ),
              }))
            )
          );

        setReserved(
          (reservations || []).some(
            (res) =>
              res.campaign_id === campaign.campaign.id && res.status === 0
          )
        );

        Promise.all(
          products
            .map((p) => p.business_restrictions)
            .filter((res) => res)
            .flat()
            .map((bId) => bmapi.getBusiness(bId))
        ).then(setBusinesses);

        if (userId && campaign.campaign.loop_campaign) {
          bmapi.getCampaignTerms(campaign.campaign.id).then((st) => {
            setStores(
              st.length === 0 ||
                st.filter((s) => s.signed && s.business_name && !s.demo)
            );
          });
        }

        if (campaign.campaign.type === PRODUCT_TYPES.CAMPAIGN_EARNING_CARD) {
          setPrizes(campaign.campaign.rules.prizes || []);
        }

        if (
          campaign.campaign.front_end_type === PRODUCTS.COURSE_PASS &&
          userId
        ) {
          updateCheckins();
        }
      })
      .catch((e) => notifyError(getErrorMessageString(e, intl)))
      .finally(stopLoading);
  }, [
    bmapi,
    campaignId,
    intl,
    notifyError,
    startLoading,
    stopLoading,
    updateCheckins,
    userId,
  ]);

  useEffect(() => {
    updateData();
  }, [updateData]);

  function getProductExpiration() {
    const nextProd = getFirstToUseProduct();
    return nextProd && nextProd.expiration_date;
  }

  function getFirstToUseProduct() {
    return products.sort(byExpiration)[0];
  }

  function formatTransaction(campaign, palette) {
    return (t) => {
      switch (campaign.type) {
        case PRODUCT_TYPES.CAMPAIGN_COUPON:
          switch (t.type) {
            case TX_TYPES.ISSUE:
              return {
                id: t.id,
                counter: t.counter || 1,
                title: intl.formatMessage(campaignMsg.couponReceived, {
                  counter: t.counter || 1,
                }),
                subtitle: intl.formatMessage(campaignMsg.couponReceivedInfo, {
                  date: new Date(t.timestamp),
                  store: t.business_name,
                }),
                Icon: FiberNewIcon,
                iconBg: palette.info.main,
              };
            case TX_TYPES.BURN:
              return {
                id: t.id,
                counter: t.counter || 1,
                title: intl.formatMessage(campaignMsg.couponUsed, {
                  counter: t.counter || 1,
                }),
                subtitle: intl.formatMessage(campaignMsg.couponReceivedInfo, {
                  date: new Date(t.timestamp),
                  store: t.business_name,
                }),
                Icon: ArrowBackIcon,
                iconBg: palette.warning.main,
              };
            case TX_TYPES.EXPIRE:
              return {
                id: t.id,
                counter: t.counter || 1,
                title: intl.formatMessage(campaignMsg.couponExpired, {
                  counter: t.counter || 1,
                }),
                subtitle: intl.formatMessage(campaignMsg.couponReceivedInfo, {
                  date: new Date(t.timestamp),
                  store: t.business_name,
                }),
                Icon: TimerOffIcon,
                iconBg: palette.error.main,
              };

            default:
              break;
          }
          break;

        case PRODUCT_TYPES.CAMPAIGN_EVENT_PASS:
          switch (t.type) {
            case TX_TYPES.ISSUE:
              return {
                id: t.id,
                counter: t.counter || 1,
                title: intl.formatMessage(campaignMsg.passReceived, {
                  counter: t.counter || 1,
                }),
                subtitle: intl.formatMessage(campaignMsg.couponReceivedInfo, {
                  date: new Date(t.timestamp),
                  store: t.business_name,
                }),
                Icon: FiberNewIcon,
                iconBg: palette.info.main,
              };
            case TX_TYPES.BURN:
              return {
                id: t.id,
                counter: t.counter || 1,
                title: intl.formatMessage(campaignMsg.passUsed, {
                  counter: t.counter || 1,
                }),
                subtitle: intl.formatMessage(campaignMsg.couponReceivedInfo, {
                  date: new Date(t.timestamp),
                  store: t.business_name,
                }),
                Icon: ArrowBackIcon,
                iconBg: palette.warning.main,
              };
            case TX_TYPES.EXPIRE:
              return {
                id: t.id,
                counter: t.counter || 1,
                title: intl.formatMessage(campaignMsg.passExpired, {
                  counter: t.counter || 1,
                }),
                subtitle: intl.formatMessage(campaignMsg.couponReceivedInfo, {
                  date: new Date(t.timestamp),
                  store: t.business_name,
                }),
                Icon: TimerOffIcon,
                iconBg: palette.error.main,
              };

            default:
              break;
          }
          break;

        case PRODUCT_TYPES.CAMPAIGN_SHOPPING_CARD:
          switch (t.type) {
            case TX_TYPES.ISSUE:
              return {
                id: t.id,
                counter: t.counter || 1,
                title: intl.formatMessage(campaignMsg.cardsReceived, {
                  counter: t.counter || 1,
                }),
                subtitle: intl.formatMessage(campaignMsg.couponReceivedInfo, {
                  date: new Date(t.timestamp),
                  store: t.business_name,
                }),
                Icon: FiberNewIcon,
                iconBg: palette.info.main,
              };
            case TX_TYPES.DECREASE:
              return {
                id: t.id,
                counter: t.counter || 1,
                title: intl.formatMessage(campaignMsg.cardValueUsed, {
                  value: t.value / 100,
                  currency: campaign.rules.currency,
                }),
                subtitle: intl.formatMessage(campaignMsg.couponReceivedInfo, {
                  date: new Date(t.timestamp),
                  store: t.business_name,
                }),
                Icon: ArrowBackIcon,
                iconBg: palette.warning.main,
              };
            case TX_TYPES.BURN:
              return {
                id: t.id,
                counter: t.counter || 1,
                title: intl.formatMessage(campaignMsg.emptyCard, {
                  counter: t.counter || 1,
                }),
                subtitle: intl.formatMessage(campaignMsg.couponReceivedInfo, {
                  date: new Date(t.timestamp),
                  store: t.business_name,
                }),
                Icon: CancelPresentationIcon,
                iconBg: palette.error.main,
              };
            case TX_TYPES.EXPIRE:
              return {
                id: t.id,
                counter: t.counter || 1,
                title: intl.formatMessage(campaignMsg.cardExpired, {
                  counter: t.counter || 1,
                }),
                subtitle: intl.formatMessage(campaignMsg.couponReceivedInfo, {
                  date: new Date(t.timestamp),
                  store: t.business_name,
                }),
                Icon: TimerOffIcon,
                iconBg: palette.error.main,
              };

            default:
              break;
          }
          break;

        case PRODUCT_TYPES.CAMPAIGN_EARNING_CARD:
          switch (t.type) {
            case TX_TYPES.ISSUE:
              return {
                id: t.id,
                counter: t.counter || 1,
                title:
                  t.description === TX_DESCRIPTIONS.PRIZE_ISSUE
                    ? intl.formatMessage(campaignMsg.prizeReceived)
                    : intl.formatMessage(campaignMsg.cardReceived),
                subtitle: intl.formatMessage(campaignMsg.couponReceivedInfo, {
                  date: new Date(t.timestamp),
                  store: t.business_name,
                }),
                Icon:
                  t.description === TX_DESCRIPTIONS.PRIZE_ISSUE
                    ? EmojiEventsIcon
                    : FiberNewIcon,
                iconBg:
                  t.description === TX_DESCRIPTIONS.PRIZE_ISSUE
                    ? palette.warning.main
                    : palette.info.main,
              };
            case TX_TYPES.DECREASE:
              return {
                id: t.id,
                counter: t.counter || 1,
                title: intl.formatMessage(campaignMsg.pointsUsed, {
                  value: t.value / 100,
                }),
                subtitle: intl.formatMessage(campaignMsg.couponReceivedInfo, {
                  date: new Date(t.timestamp),
                  store: t.business_name,
                }),
                Icon: ArrowBackIcon,
                iconBg: palette.error.main,
              };
            case TX_TYPES.INCREASE:
              return {
                id: t.id,
                counter: t.counter || 1,
                title: intl.formatMessage(campaignMsg.pointsReceived, {
                  value: t.value / 100,
                }),
                subtitle: intl.formatMessage(campaignMsg.couponReceivedInfo, {
                  date: new Date(t.timestamp),
                  store: t.business_name,
                }),
                Icon: ArrowForwardIcon,
                iconBg: palette.success.main,
              };
            case TX_TYPES.INCREASE_BONUS:
              return {
                id: t.id,
                counter: t.counter || 1,
                title: intl.formatMessage(campaignMsg.bonusReceived),
                subtitle: intl.formatMessage(campaignMsg.couponReceivedInfo, {
                  date: new Date(t.timestamp),
                  store: t.business_name,
                }),
                Icon: Add,
                iconBg: palette.info.main,
              };
            case TX_TYPES.FRIEND_BONUS:
              return {
                id: t.id,
                counter: t.counter || 1,
                title: intl.formatMessage(campaignMsg.friendBonusReceived),
                subtitle: intl.formatMessage(campaignMsg.couponReceivedInfo, {
                  date: new Date(t.timestamp),
                  store: t.business_name,
                }),
                Icon: Add,
                iconBg: palette.info.main,
              };
            case TX_TYPES.PROFILE_WEEKLY_BONUS:
              return {
                id: t.id,
                counter: t.counter || 1,
                title: intl.formatMessage(campaignMsg.weeklyBonusReceived),
                subtitle: intl.formatMessage(campaignMsg.couponReceivedInfo, {
                  date: new Date(t.timestamp),
                  store: t.business_name,
                }),
                Icon: Add,
                iconBg: palette.info.main,
              };
            case TX_TYPES.EXPIRE:
              return {
                id: t.id,
                counter: t.counter || 1,
                title: intl.formatMessage(campaignMsg.cardExpired, {
                  counter: t.counter || 1,
                }),
                subtitle: intl.formatMessage(campaignMsg.couponReceivedInfo, {
                  date: new Date(t.timestamp),
                  store: t.business_name,
                }),
                Icon: TimerOffIcon,
                iconBg: palette.error.main,
              };
            case TX_TYPES.PLAY:
              return {
                id: t.id,
                counter: t.counter || 1,
                title:
                  t.description === TX_DESCRIPTIONS.WINNER
                    ? intl.formatMessage(campaignMsg.win, {
                        counter: t.counter || 1,
                      })
                    : intl.formatMessage(campaignMsg.played, {
                        counter: t.counter || 1,
                      }),
                subtitle: intl.formatMessage(campaignMsg.couponReceivedInfo, {
                  date: new Date(t.timestamp),
                  store: t.business_name,
                }),
                Icon:
                  t.description === TX_DESCRIPTIONS.WINNER
                    ? EmojiEventsIcon
                    : Clear,
                iconBg:
                  t.description === TX_DESCRIPTIONS.WINNER
                    ? palette.success.main
                    : palette.warning.main,
              };

            default:
              break;
          }
          break;

        default:
          break;
      }
      return false;
    };
  }

  function getProductInfo(products, type, businesses = []) {
    switch (type) {
      case PRODUCT_TYPES.CAMPAIGN_SHOPPING_CARD:
        return products.map((p) => ({
          id: p.id,
          title: intl.formatMessage(campaignMsg.giftcardBalance, {
            balance: p.available_value / 100,
            currency: p.currency,
          }),
          subtitle: intl.formatMessage(campaignMsg.expiration, {
            expirationDate: new Date(p.expiration_date),
          }),
          Icon: IconsMap[p.subtype],
        }));

      case PRODUCT_TYPES.CAMPAIGN_EVENT_PASS:
      case PRODUCT_TYPES.CAMPAIGN_COUPON:
        return products
          .reduce(
            (acc, p) =>
              acc.some(
                (prod) =>
                  prod.expiration_date ===
                    endOfDay(new Date(p.expiration_date)).toISOString() &&
                  prod.business_restrictions?.join(";") ===
                    p.business_restrictions?.join(";") &&
                  prod.available_shots === p.available_shots
              )
                ? acc
                : [
                    ...acc,
                    {
                      product: p,
                      expiration_date: endOfDay(
                        new Date(p.expiration_date)
                      ).toISOString(),
                      available_shots: p.available_shots,
                      counter: products.filter(
                        (prod) =>
                          endOfDay(
                            new Date(prod.expiration_date)
                          ).toISOString() ===
                            endOfDay(
                              new Date(p.expiration_date)
                            ).toISOString() &&
                          prod.business_restrictions?.join(";") ===
                            p.business_restrictions?.join(";") &&
                          prod.available_shots === p.available_shots
                      ).length,
                    },
                  ],
            []
          )
          .map((p) => {
            const restrictions = p.product.business_restrictions
              ? p.product.business_restrictions
                  .map((bs) => businesses.find((b) => b.id === bs)?.name)
                  .join(", ")
              : false;

            return {
              id: p.product.id,
              title:
                type === PRODUCT_TYPES.CAMPAIGN_EVENT_PASS
                  ? intl.formatMessage(
                      restrictions
                        ? campaignMsg.eventPassInfoRestricted
                        : campaignMsg.eventPassInfo,
                      {
                        counter: p.counter,
                        restrictions,
                      }
                    )
                  : p.product.subtype === PRODUCT_SUBTYPES.COUPON_VALUE
                  ? intl.formatMessage(
                      restrictions
                        ? campaignMsg.couponValueInfoRestricted
                        : campaignMsg.couponValueInfo,
                      {
                        counter: p.counter,
                        value:
                          (p.counter *
                            p.product.value *
                            (p.product.shots === 0 ? 1 : p.available_shots)) /
                          100,
                        currency: p.product.currency,
                        restrictions,
                      }
                    )
                  : intl.formatMessage(
                      restrictions
                        ? campaignMsg.couponInfoRestricted
                        : campaignMsg.couponInfo,
                      {
                        counter: p.counter,
                        restrictions,
                      }
                    ),
              subtitle: intl.formatMessage(campaignMsg.expiration, {
                expirationDate: new Date(p.expiration_date),
              }),
              counter: p.counter,
              Icon: IconsMap[p.product.subtype],
            };
          });

      default:
        return [];
    }
  }

  const prizesTier = new Set(
    prizes?.map((p) => p.threshold).sort((a, b) => a - b)
  );

  const prizesForTier = (tier) => {
    return prizes.filter((p) => p.threshold === tier);
  };

  const availability = useMemo(() => (stats ? getAvailability(stats) : 0), [
    stats,
  ]);

  const redeem = useCallback(
    (prizeId) => {
      startLoading();

      bmapi
        .redeemPrize(products[0].id, prizeId)
        .then(() => {
          notifySuccess(intl.formatMessage(campaignMsg.prizeReceivedMessage));
          updateData();
        })
        .catch((e) => notifyError(getErrorMessageString(e, intl)))
        .finally(stopLoading);
    },
    [
      bmapi,
      intl,
      notifyError,
      notifySuccess,
      products,
      startLoading,
      stopLoading,
      updateData,
    ]
  );

  const createLink = (str) =>
    campaign.tos_consumer_url.includes("://") ? (
      <Link
        key={campaign.tos_consumer_url}
        href={campaign.tos_consumer_url}
        target="_blank"
        rel="noopener noreferrer"
      >
        {str}
      </Link>
    ) : (
      <Link
        component={RouterLink}
        key={campaign.tos_consumer_url}
        to={campaign.tos_consumer_url}
        target="_blank"
        rel="noopener noreferrer"
      >
        {str}
      </Link>
    );

  const checkoutEvent = () => {
    bmapi.checkoutEvent(lesson.business_event_id).then(() => {
      updateCheckins();
      notifySuccess(
        intl.formatMessage({
          id: "common.checkoutDone",
          defaultMessage: "Effettuato chekout",
        })
      );
    });
  };

  const byDate = (a, b) => new Date(a.check_in_at) - new Date(b.check_in_at);

  return (
    !!campaign && (
      <Container maxWidth="sm">
        <Dialog open={viewStores} onClose={handleCloseStores}>
          <DialogTitle>
            {intl.formatMessage(campaignMsg.participatingStores)}
          </DialogTitle>
          <DialogContent>
            {stores === false ? (
              intl.formatMessage(campaignMsg.loading)
            ) : stores === true ? (
              intl.formatMessage(campaignMsg.allStores)
            ) : stores.length === 0 ? (
              intl.formatMessage(campaignMsg.noStores)
            ) : (
              <ul>
                {stores.map((s) => (
                  <li key={s.business_id}>{s.business_name}</li>
                ))}
              </ul>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseStores} color="primary" autoFocus>
              {intl.formatMessage(common.ok)}
            </Button>
          </DialogActions>
        </Dialog>

        <Box my={2}>
          <Button
            component={RouterLink}
            to={CONSUMER_ROUTES.HOME}
            startIcon={<ArrowBackIcon />}
          >
            {intl.formatMessage(common.back)}
          </Button>
        </Box>

        <Box mb={4}>
          <Product
            campaign={campaign}
            products={products}
            issuedQuantity={issuedQuantity}
            availability={stats ? availability : 0}
            getProductExpiration={getProductExpiration}
            showCardImage
            onUpdate={updateData}
            reserved={reserved}
          />
        </Box>

        {userId &&
          !!lesson &&
          campaign.front_end_type === PRODUCTS.COURSE_PASS && (
            <Box mb={4}>
              <Card>
                <CardContent>
                  <Grid
                    container
                    spacing={1}
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography variant="h5">
                        {intl.formatMessage(common.eventRunning)}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Lens style={{ color: theme.palette.error.main }} />
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions>
                  <Button
                    startIcon={<ExitToApp />}
                    color="primary"
                    onClick={checkoutEvent}
                  >
                    {intl.formatMessage({
                      id: "common.doCheckout",
                      defaultMessage: "Effettua checkout",
                    })}
                  </Button>
                </CardActions>
              </Card>
            </Box>
          )}

        {userId && campaign.front_end_type === PRODUCTS.COURSE_PASS && (
          <Box mb={4}>
            <Card>
              <CardContent>
                <Typography variant="h5">
                  {intl.formatMessage({
                    id: "component.campaign.checkin",
                    defaultMessage: "Presenze",
                  })}
                </Typography>
              </CardContent>
              {checkins &&
                (checkins.length ? (
                  <List dense>
                    {Object.values(
                      checkins.reduce(
                        (a, c) => ({
                          ...a,
                          [c.business_event_id]: [
                            ...(a[c.business_event_id] || []),
                            c,
                          ],
                        }),
                        {}
                      )
                    ).map((checkin) => (
                      <ListItem key={checkin[0].id}>
                        <ListItemText
                          primary={`${intl.formatDate(
                            new Date(checkin[0].check_in_at)
                          )} @ ${checkin[0].location_name}`}
                          secondary={checkin
                            .sort(byDate)
                            .map(
                              (c) =>
                                `${format(
                                  new Date(c.check_in_at),
                                  "HH:mm"
                                )} -> ${format(
                                  new Date(c.check_out_at),
                                  "HH:mm"
                                )}`
                            )
                            .join(" | ")}
                        />
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  <CardContent>
                    <Typography>
                      {intl.formatMessage({
                        id: "component.campaign.noCheckin",
                        defaultMessage: "Nessuna presenza ancora registrata",
                      })}
                    </Typography>
                  </CardContent>
                ))}
            </Card>
          </Box>
        )}

        {bmapi.settings.theme !== TENANTS.ANCOT &&
          campaign.type !== PRODUCT_TYPES.CAMPAIGN_EARNING_CARD &&
          products?.filter((p) => p.status === 0).length > 0 && (
            <Box mb={4}>
              <Card>
                <List>
                  {getProductInfo(
                    products.filter((p) => p.status === 0),
                    campaign.type,
                    businesses
                  ).map((item) => (
                    <ProductCard key={item.id} {...item} />
                  ))}
                  {(products || [])
                    .filter((p) => p.status === 4)
                    .map((prod) => (
                      <SharedProduct
                        key={prod.id}
                        campaign={campaign}
                        permission={productsPermissions[prod.id]?.[0]}
                        product={prod}
                        onUpdate={updateData}
                      />
                    ))}
                </List>
              </Card>
            </Box>
          )}

        {campaign.rules.subtype === PRODUCT_SUBTYPES.EARNING_CARD_SIMPLE &&
          Array.isArray(prizes) &&
          prizes.length > 0 && (
            <Box mb={4}>
              <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} id="prizes">
                  <Typography>
                    {intl.formatMessage(campaignMsg.getPrizes)}
                  </Typography>
                </AccordionSummary>

                <Timeline style={{ padding: 0 }}>
                  {[...prizesTier].map((p, i) => (
                    <TimelineItem key={p}>
                      <TimelineOppositeContent>
                        <Typography variant="body2" color="textSecondary">
                          {intl.formatMessage(common.totalPoints, {
                            points: p / 100,
                          })}
                        </Typography>
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        {products[0]?.available_value > p ? (
                          <TimelineDot color="primary">
                            <EmojiEventsIcon />
                          </TimelineDot>
                        ) : (
                          <TimelineDot color="grey">
                            <AdjustIcon />
                          </TimelineDot>
                        )}
                        {i < prizesTier.size - 1 && <TimelineConnector />}
                      </TimelineSeparator>
                      <TimelineContent style={{ flexGrow: 4 }}>
                        {prizesForTier(p).map((prize) => (
                          <Typography gutterBottom key={prize.id}>
                            {prize.name}{" "}
                            {products[0]?.available_value > p && (
                              <Button
                                color="primary"
                                size="small"
                                onClick={() => redeem(prize.id)}
                              >
                                {intl.formatMessage(
                                  campaignMsg.redeemPrizeAction
                                )}
                              </Button>
                            )}
                          </Typography>
                        ))}
                      </TimelineContent>
                    </TimelineItem>
                  ))}
                </Timeline>
              </Accordion>
            </Box>
          )}

        {(bmapi.settings.theme !== TENANTS.ANCOT ||
          campaign.rules.main_event_id !== "") && (
          <Box mb={4}>
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>
                  {intl.formatMessage(campaignMsg.details)}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ display: "block" }}>
                {campaign.description.split("\n").map(
                  (item, key, arr) =>
                    item && (
                      <Typography
                        variant="body2"
                        key={key}
                        gutterBottom={!arr[key + 1]}
                      >
                        {item}
                      </Typography>
                    )
                )}
                {campaign.tos_consumer_url && (
                  <Box mt={1}>
                    <Typography variant="body2">
                      {intl.formatMessage(campaignMsg.termOfService, {
                        link: createLink,
                      })}
                    </Typography>
                  </Box>
                )}
                {userId && campaign.loop_campaign && (
                  <Box mt={1}>
                    <Typography variant="body2">
                      <Link onClick={() => setViewStores(true)}>
                        {intl.formatMessage(campaignMsg.participatingStores)}
                      </Link>
                    </Typography>
                  </Box>
                )}
              </AccordionDetails>
            </Accordion>
          </Box>
        )}

        {bmapi.settings.theme === TENANTS.ANCOT &&
          campaign.type === PRODUCT_TYPES.CAMPAIGN_EVENT_PASS &&
          campaign.rules.main_event_id === "" && (
            <Box mb={4}>
              <Accordion expanded={false}>
                <CardContent>
                  <Typography variant="h6">
                    Situazione crediti formativi
                  </Typography>
                </CardContent>
              </Accordion>
              {(bmapi.userInfo.Courses || [])
                .sort((a, b) => b.Info.Year - a.Info.Year)
                .map((course) => (
                  <Accordion key={course.Info.Year}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography>
                        {course.Info.Year}. Crediti{" "}
                        {course.Info.CreditsAccumulated}/
                        {course.Info.CreditsGoal}
                      </Typography>
                    </AccordionSummary>
                    {(course.Events || []).map((event, i) => (
                      <ProductCard
                        key={i}
                        title={event.Name}
                        subtitle={`${intl.formatDate(new Date(event.Date))} | ${
                          event.Credits
                        } crediti`}
                        Icon={EventIcon}
                      />
                    ))}
                  </Accordion>
                ))}
            </Box>
          )}

        {stats &&
          campaign.front_end_type !== PRODUCTS.COURSE_PASS &&
          bmapi.settings.theme !== TENANTS.ANCOT && (
            <Box mb={4}>
              <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>
                    {intl.formatMessage(common.statistics)}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails style={{ display: "block" }}>
                  {getDetails(stats, availability).map((d, i) => (
                    <ProductDetails
                      key={i}
                      label={intl.formatMessage(d.label)}
                      value={d.value}
                    />
                  ))}
                </AccordionDetails>
              </Accordion>
            </Box>
          )}

        {products?.length > 0 &&
          campaign.front_end_type !== PRODUCTS.COURSE_PASS &&
          bmapi.settings.theme !== TENANTS.ANCOT && (
            <Box mb={4}>
              <Accordion
                expanded={viewTransactions}
                onChange={(_, f) => handleOpenTransactions(f)}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>
                    {intl.formatMessage(common.transactions)}
                  </Typography>
                </AccordionSummary>
                {!transactions ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    m={2}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  transactions
                    .reduce(mergeTxs, [])
                    .map(formatTransaction(campaign, bmapi.theme.app.palette))
                    .filter((item) => Boolean(item))
                    .map((item) => <ProductCard key={item.id} {...item} />)
                )}
              </Accordion>
            </Box>
          )}

        <Box my={2}>
          <Button
            component={RouterLink}
            to={CONSUMER_ROUTES.HOME}
            startIcon={<ArrowBackIcon />}
          >
            {intl.formatMessage(common.back)}
          </Button>
        </Box>
      </Container>
    )
  );
}
