import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { addDays, addMonths, endOfDay } from "date-fns";

import {
  Chip,
  FormControl,
  Grid,
  MenuItem,
  TextField,
} from "@material-ui/core";

import NumberInput from "../../NumberInput";
import styles from "../../../utils/styles";
import {
  PRODUCT_SUBTYPES,
  PRODUCT_TYPES,
  ROLES,
} from "../../../utils/constants";
import { useBmapi } from "../../../utils/bmapi-context";
import { form } from "../../../messages";

export default function Prizes({ values, handleChange, campaigns = [] }) {
  const intl = useIntl();
  const classes = styles.useStyles();
  const { bmapi } = useBmapi();
  const input = useRef();
  const [threshold, setThreshold] = useState("");
  const [possiblePrizes, setPossiblePrizes] = useState([]);
  const [prizes, setPrizes] = useState(
    values.prizes.map((p) => ({ ...p, key: Math.random() })) || []
  );

  useEffect(() => {
    const isPossiblePrize = (c) =>
      [
        PRODUCT_SUBTYPES.COUPON_DISCOUNT,
        PRODUCT_SUBTYPES.COUPON_SIMPLE,
        PRODUCT_SUBTYPES.COUPON_VALUE,
        PRODUCT_SUBTYPES.EVENT_PASS_SIMPLE,
        PRODUCT_SUBTYPES.SHOPPING_CARD_SIMPLE,
        PRODUCT_SUBTYPES.PROVISIONING_CARD,
      ].includes(c.type);

    const canAdd = (c) =>
      bmapi.getUserInfo().role === ROLES.TENANT_MANAGER ||
      bmapi.getUserInfo().business.id === c.business_owner_id;

    const notExpired = (c) => new Date(c.expiration_date) > new Date();

    setPossiblePrizes(
      campaigns?.filter(isPossiblePrize).filter(canAdd).filter(notExpired)
    );
  }, [campaigns, bmapi]);

  useEffect(() => {
    if (prizes.length !== values.prizes.length) {
      handleChange("prizes")(prizes);
    }
  }, [prizes, handleChange, values.prizes.length]);

  const addPrize = (prize) => {
    if (threshold && prize) {
      const prizeObj = possiblePrizes.find((c) => c.id === prize);
      setPrizes((p) => [
        ...p,
        {
          key: Math.random(),
          description: prizeObj.description,
          name: prizeObj.name,
          prize_id: prizeObj.campaign_id,
          threshold: Math.round(parseInt(threshold) * 100),
        },
      ]);
      setThreshold("");
      window.setTimeout(() => input.current.focus(), 0);
    }
  };

  const removePrize = (key) => {
    setPrizes((ps) => ps.filter((p) => p.key !== key));
  };

  const byPoints = (a, b) => a.threshold - b.threshold;

  const lastDatePossible = () =>
    addDays(
      values.product_limit_date ||
        addMonths(values.expiration_date, values.product_lifespan || 0),
      values.grace_period || 0
    );

  return (
    [PRODUCT_TYPES.CAMPAIGN_EARNING_CARD].includes(values.type) && (
      <FormControl margin="normal">
        <Grid container spacing={2} alignItems="flex-start">
          <Grid item xs={4}>
            <NumberInput
              name="threshold"
              label={intl.formatMessage(form.threshold)}
              value={threshold}
              onChange={(e) => setThreshold(e.target.value)}
              fullWidth
              inputRef={input}
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              select
              label={intl.formatMessage(form.choosePrize)}
              value={""}
              onChange={(e) => addPrize(e.target.value)}
              fullWidth
              disabled={!possiblePrizes || !threshold}
              helperText={intl.formatMessage(form.minExpiration, {
                expirationDate: lastDatePossible(),
              })}
            >
              {possiblePrizes?.length ? (
                (possiblePrizes || []).map((c) => (
                  <MenuItem
                    key={c.id}
                    value={c.id}
                    disabled={
                      endOfDay(new Date(c.expiration_date)) <
                      endOfDay(lastDatePossible())
                    }
                  >
                    {c.name} [
                    {intl.formatMessage(form.expiration, {
                      expirationDate: new Date(c.expiration_date),
                    })}
                    ]
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="">{intl.formatMessage(form.noPrize)}</MenuItem>
              )}
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={1}>
              {prizes.sort(byPoints).map((p) => (
                <Grid item key={p.key} classes={{ root: classes.chipWrapper }}>
                  <Chip
                    label={intl.formatMessage(form.prizeDetail, {
                      prize: p.name,
                      threshold: p.threshold / 100,
                    })}
                    onDelete={() => removePrize(p.key)}
                    color="secondary"
                    classes={{ root: classes.chip }}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </FormControl>
    )
  );
}
