import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import {
  CircularProgress,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import {
  AddCircleOutline,
  Close,
  ControlPointDuplicate,
  Delete,
  Edit,
  Equalizer,
  Info,
  MoreVert,
  PlayArrow,
  PlaylistAddCheck,
  RemoveCircleOutline,
  Send,
  Share,
  Stop,
} from "@material-ui/icons";

import CampaignDelete from "../ui/CampaignDelete";
import CampaignEventStart from "../ui/CampaignEventStart";
import CampaignEventStop from "../ui/CampaignEventStop";
import CampaignJoin from "../ui/CampaignJoin";
import CampaignUnjoin from "../ui/CampaignUnjoin";
import IssueProduct from "../ui/IssueProduct";
import { useBmapi } from "../utils/bmapi-context";
import { getLabel } from "../utils/campaigns";
import {
  CAMPAIGN_ACTION,
  EVENT_STATUS,
  FEATURES,
  MANAGER_ROUTES,
  PRODUCTS,
  ROLES,
  SKINS,
  VARIANTS,
} from "../utils/constants";
import { getErrorMessageString } from "../utils/errors";
import {
  actions as actionsMsg,
  notifications,
  product as productMsg,
} from "../messages";
import styles from "../utils/styles";

export default function CampaignActions({
  campaign,
  collapse = true,
  events = [],
  edge,
  filter,
  hideInfo,
  isPrize,
  loading,
  loadPerformance,
  onUpdate,
  performance,
  showInfo,
  trigger,
  visibleInfo,
}) {
  const history = useHistory();
  const intl = useIntl();
  const classes = styles.useStyles();
  const { bmapi, notifyError, notifySuccess } = useBmapi();

  const [anchorEl, setAnchorEl] = useState(null);
  const [sending, setSending] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [startingEvent, setStartingEvent] = useState(false);
  const [stoppingEvent, setStoppingEvent] = useState(false);
  const [joining, setJoining] = useState(false);
  const [unjoining, setUnjoining] = useState(false);

  const copyLink = () => {
    navigator.clipboard
      .writeText(campaign.shareLink)
      .then(() => notifySuccess(intl.formatMessage(notifications.linkCopied)))
      .catch((e) => notifyError(getErrorMessageString(e, intl)));
  };

  const goTo = (route, id) => () =>
    history.push(route.replace(":campaignId", id));

  const afterDelete = () => {
    setDeleting(false);
    onUpdate();
  };

  const afterStart = () => {
    setStartingEvent(false);
    goTo(MANAGER_ROUTES.CAMPAIGN, campaign.campaign_id)();
  };

  const afterStop = () => {
    setStoppingEvent(false);
    onUpdate();
  };

  const afterJoin = () => {
    setJoining(false);
    onUpdate();
  };

  const afterUnjoin = () => {
    setUnjoining(false);
    onUpdate();
  };

  const canDelete = () =>
    campaign.ownCampaign &&
    !isPrize &&
    (campaign.demo ||
      (performance?.issued_value === 0 && performance?.issued_qty === 0));

  const CampaignJoinDialog = () => (
    <CampaignJoin
      campaign={campaign}
      open={joining}
      onSuccess={afterJoin}
      onCancel={() => setJoining(false)}
    />
  );

  const CampaignUnjoinDialog = () => (
    <CampaignUnjoin
      campaign={campaign}
      open={unjoining}
      onSuccess={afterUnjoin}
      onCancel={() => setUnjoining(false)}
    />
  );

  const IssueProductDialog = () => (
    <IssueProduct
      campaign={campaign}
      open={sending}
      onClose={() => setSending(false)}
    />
  );

  const CampaignDeleteDialog = () => (
    <CampaignDelete
      campaign={campaign}
      open={deleting}
      onSuccess={afterDelete}
      onCancel={() => setDeleting(false)}
    />
  );

  const CampaignEventStartDialog = () => (
    <CampaignEventStart
      campaign={campaign}
      open={startingEvent}
      onSuccess={afterStart}
      onCancel={() => setStartingEvent(false)}
    />
  );

  const CampaignEventStopDialog = () => (
    <CampaignEventStop
      campaign={campaign}
      event={events.find((e) => e.status === EVENT_STATUS.STARTED)}
      open={stoppingEvent}
      onSuccess={afterStop}
      onCancel={() => setStoppingEvent(false)}
    />
  );

  const actions = [
    {
      action: goTo(MANAGER_ROUTES.CAMPAIGN, campaign.campaign_id),
      Icon: Info,
      id: CAMPAIGN_ACTION.LINK,
      label: intl.formatMessage(actionsMsg.info),
    },
    {
      action: visibleInfo ? hideInfo : showInfo,
      disabled: campaign.toSign,
      Icon: visibleInfo ? Close : Equalizer,
      id: CAMPAIGN_ACTION.MOREINFO,
      label: intl.formatMessage(actionsMsg.stats),
    },
    {
      action: () => setStartingEvent(true),
      disabled:
        !campaign.isActive ||
        bmapi.getUserInfo().role === ROLES.TENANT_MANAGER ||
        campaign.campaign_data.front_end_type !== PRODUCTS.COURSE_PASS ||
        !bmapi.can(FEATURES.EVENTS) ||
        (events || []).some((e) => e?.status === EVENT_STATUS.STARTED),
      Icon: PlayArrow,
      id: CAMPAIGN_ACTION.START,
      label: intl.formatMessage(actionsMsg.start),
      render: CampaignEventStartDialog,
    },
    {
      action: () => setStoppingEvent(true),
      disabled:
        campaign.campaign_data.front_end_type !== PRODUCTS.COURSE_PASS ||
        !bmapi.can(FEATURES.EVENTS) ||
        (events || []).every((e) => e?.status !== EVENT_STATUS.STARTED),
      Icon: Stop,
      id: CAMPAIGN_ACTION.STOP,
      label: intl.formatMessage(actionsMsg.stop),
      render: CampaignEventStopDialog,
    },
    {
      action: () => setJoining(true),
      disabled: !campaign.toSign,
      Icon: AddCircleOutline,
      id: CAMPAIGN_ACTION.JOIN,
      label: intl.formatMessage(actionsMsg.join),
      render: CampaignJoinDialog,
    },
    {
      action: () => setUnjoining(true),
      disabled: !campaign.canUnjoin,
      Icon: RemoveCircleOutline,
      id: CAMPAIGN_ACTION.UNJOIN,
      label: intl.formatMessage(actionsMsg.unjoin),
      render: CampaignUnjoinDialog,
    },
    {
      action: () => setSending(true),
      disabled:
        !campaign.canIssue ||
        (bmapi.settings.skin === SKINS.EDUCATION &&
          campaign.campaign_data.front_end_type === PRODUCTS.COURSE_PASS &&
          campaign.campaign_data.rules.main_event_id === ""),
      Icon: Send,
      id: CAMPAIGN_ACTION.ISSUE,
      label: intl.formatMessage(productMsg[getLabel(campaign.type)].issue),
      render: IssueProductDialog,
    },
    {
      action: goTo(MANAGER_ROUTES.RESERVATIONS, campaign.campaign_id),
      disabled: !campaign.canIssue || !campaign.campaign_data.waiting_list,
      Icon: PlaylistAddCheck,
      id: CAMPAIGN_ACTION.WAITINGLIST,
      label: intl.formatMessage(actionsMsg.waiting),
    },
    {
      action: copyLink,
      disabled: !campaign.campaign_data.link_distribution,
      Icon: Share,
      id: CAMPAIGN_ACTION.SHARE,
      label: intl.formatMessage(actionsMsg.copyLink),
    },
    {
      action: goTo(MANAGER_ROUTES.EDIT_CAMPAIGN, campaign.campaign_id),
      disabled: !campaign.ownCampaign || !bmapi.can(FEATURES.MANAGE_CAMPAIGN),
      Icon: Edit,
      id: CAMPAIGN_ACTION.EDIT,
      label: intl.formatMessage(actionsMsg.edit),
    },
    {
      action: goTo(MANAGER_ROUTES.CLONE_CAMPAIGN, campaign.campaign_id),
      disabled: !campaign.ownCampaign || !bmapi.can(FEATURES.MANAGE_CAMPAIGN),
      Icon: ControlPointDuplicate,
      id: CAMPAIGN_ACTION.CLONE,
      label: intl.formatMessage(actionsMsg.clone),
    },
    {
      action: () => setDeleting(true),
      color: VARIANTS.ERROR,
      disabled: !canDelete() || !bmapi.can(FEATURES.MANAGE_CAMPAIGN),
      Icon: Delete,
      id: CAMPAIGN_ACTION.DELETE,
      label: intl.formatMessage(actionsMsg.delete),
      render: CampaignDeleteDialog,
    },
  ].filter((a) => (!filter || filter(a)) && !a.disabled);

  const firstAction = actions[0];

  const handleOpen = (e) => {
    const target = e.currentTarget;
    if (performance) {
      setAnchorEl(target);
    } else {
      loadPerformance().then(() => setAnchorEl(target));
    }
  };

  return (
    <span style={{ display: "inline-block", position: "relative" }}>
      {actions.length === 1 && collapse && !trigger ? (
        <IconButton
          edge={edge}
          aria-label={firstAction.label}
          onClick={firstAction.action}
        >
          {!!firstAction.render && firstAction.render()}
          <firstAction.Icon />
        </IconButton>
      ) : (
        <React.Fragment>
          {trigger ? (
            trigger(handleOpen)
          ) : (
            <IconButton onClick={handleOpen} edge={edge}>
              <MoreVert />
            </IconButton>
          )}
          <Menu
            anchorEl={anchorEl}
            keepMounted={
              sending ||
              deleting ||
              joining ||
              unjoining ||
              startingEvent ||
              stoppingEvent
            }
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            <MenuItem disabled>
              <Typography variant="overline">{campaign.name}</Typography>
            </MenuItem>
            {actions.map((action) => (
              <MenuItem
                key={action.label}
                onClick={() => {
                  setAnchorEl(null);
                  action.action();
                }}
                disabled={loading}
              >
                {!!action.render && action.render()}
                <ListItemIcon>
                  <action.Icon fontSize="small" color={action.color} />
                </ListItemIcon>
                <Typography variant="inherit" color={action.color}>
                  {action.label}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        </React.Fragment>
      )}
      {loading && (
        <CircularProgress size={48} className={classes.fabProgress} />
      )}
    </span>
  );
}
