import React from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormGroup,
  Typography,
} from "@material-ui/core";

import { ExpandMore } from "@material-ui/icons";

export default function FormSection({ children, collapsed = false, title }) {
  return (
    <Box mb={2}>
      <Accordion defaultExpanded={!collapsed}>
        {title && (
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography component="legend" variant="h6" gutterBottom>
              {title}
            </Typography>
          </AccordionSummary>
        )}
        <AccordionDetails>
          <FormGroup style={{ width: "100%" }}>{children}</FormGroup>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
