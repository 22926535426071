import { defineMessages } from "react-intl";

export const navigation = defineMessages({
  account: {
    id: "navigation.consumer.account",
    defaultMessage: "Profilo",
  },
  actionForm: {
    id: "navigation.actionForm",
    defaultMessage: "Elaborazione QRcode",
  },
  appConsumer: {
    id: "navigation.appConsumer",
    defaultMessage: "App consumer",
  },
  appManager: {
    id: "navigation.appManager",
    defaultMessage: "Admin",
  },
  backToCampaigns: {
    id: "navigation.backToCampaigns",
    defaultMessage: "Torna alle campagne",
  },
  campaigns: {
    id: "navigation.manager.campaigns",
    defaultMessage: "Campagne",
  },
  campfireFaq: {
    id: "navigation.consumer.campfireFaq",
    defaultMessage: "Campfire FAQ",
  },
  campfireStats: {
    id: "navigation.manager.campfireStats",
    defaultMessage: "Campfire stats",
  },
  checkin: {
    id: "navigation.checkin",
    defaultMessage: "Checkin",
  },
  dashboard: {
    id: "navigation.manager.dashboard",
    defaultMessage: "Cruscotto",
  },
  enter: {
    id: "component.signin.title",
    defaultMessage: "Accedi",
  },
  export: {
    id: "navigation.manager.export",
    defaultMessage: "Esporta",
  },
  faq: {
    id: "common.faq",
    defaultMessage: "FAQ",
  },
  friendCode: {
    id: "common.friendCode",
    defaultMessage: "Invita un amico",
  },
  goHome: {
    id: "common.goHome",
    defaultMessage: "Vai alla home",
  },
  home: {
    id: "navigation.consumer.home",
    defaultMessage: "Home",
  },
  locations: {
    id: "navigation.manager.locations",
    defaultMessage: "Luoghi",
  },
  logout: {
    id: "navigation.logout",
    defaultMessage: "Logout",
  },
  loops: {
    id: "navigation.manager.loops",
    defaultMessage: "Circuiti",
  },
  lots: {
    id: "navigation.manager.lots",
    defaultMessage: "Assegnazione lotti",
  },
  newScan: {
    id: "component.formManager.newScan",
    defaultMessage: "Nuova scansione",
  },
  notifications: {
    id: "navigation.notifications",
    defaultMessage: "Notifiche",
  },
  prizes: {
    id: "navigation.manager.prizes",
    defaultMessage: "Assegnazione premi",
  },
  qrcode: {
    id: "navigation.consumer.qrcode",
    defaultMessage: "Il mio QR Code",
  },
  reporting: {
    id: "navigation.manager.reporting",
    defaultMessage: "Rendicontazione",
  },
  scanner: {
    id: "navigation.manager.scanner",
    defaultMessage: "Scansiona QR Code",
  },
  sendPoints: {
    id: "navigation.manager.sendPoints",
    defaultMessage: "Invia punti",
  },
  stores: {
    id: "navigation.manager.stores",
    defaultMessage: "Negozi",
  },
  subscribe: {
    id: "navigation.manager.subscribe",
    defaultMessage: "Sottoscrivi",
  },
  subscriptionSettings: {
    id: "navigation.manager.subscriptionSettings",
    defaultMessage: "Sottoscrizione",
  },
  tenantSettings: {
    id: "navigation.manager.tenantSettings",
    defaultMessage: "Tenant settings",
  },
  viewAllCampaigns: {
    id: "common.viewAll",
    defaultMessage: "Vedi tutte",
  },
});
