import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { Link as RouterLink, useParams, useHistory } from "react-router-dom";
import QRCodeGenerator from "qrcode";

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";
import {
  AddBox,
  Apps,
  ArrowBack,
  Check,
  Clear,
  Edit,
  EventNote,
  ExitToApp,
  Lens,
  PlayArrow,
  Stop,
} from "@material-ui/icons";

import { account, common } from "../../messages";
import CampaignPerformance from "../../ui/CampaignPerformance";
import { useBmapi } from "../../utils/bmapi-context";
import { useCampaigns } from "../../utils/campaigns";
import {
  CAMPAIGN_ACTION,
  EVENT_PREFIX,
  EVENT_STATUS,
  FEATURES,
  MANAGER_ROUTES,
  PRODUCTS,
  ROLES,
  USER_EVENT_STATUS,
} from "../../utils/constants";
import { getErrorMessageString } from "../../utils/errors";
import styles from "../../utils/styles";
import CampaignEventStop from "../../ui/CampaignEventStop";
import CampaignEventStart from "../../ui/CampaignEventStart";
import Ribbon from "../../ui/Ribbon";
import { format } from "date-fns";
import { formatUserData, getInitialValues } from "../Account";
import CampaignActions from "../../components/CampaignActions";

const byName = (a, b) => {
  if (a.last_name !== b.last_name)
    return a.last_name.localeCompare(b.last_name);
  if (a.complete_name !== b.complete_name)
    return a.complete_name.localeCompare(b.complete_name);
  return a.email.localeCompare(b.email);
};

function Partecipant({
  checkins,
  event,
  person,
  updateCheckins,
  updatePartecipants,
}) {
  const intl = useIntl();
  const classes = styles.useStyles();
  const { bmapi, notifyError, notifySuccess } = useBmapi();
  const [edit, setEdit] = useState(false);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [secMail, setSecMail] = useState(person.secondary_email);

  const doCheckin = () => {
    setLoading(true);
    return bmapi
      .checkinEvent(event.id, [person.id])
      .then(updateCheckins)
      .finally(() => setLoading(false));
  };
  const doCheckout = () => {
    setLoading(true);
    return bmapi
      .checkoutEvent(event.id, [person.id])
      .then(updateCheckins)
      .finally(() => setLoading(false));
  };

  const handleEdit = (e) => {
    e.preventDefault();
    setSaving(true);

    bmapi
      .saveUser(
        formatUserData(
          { ...getInitialValues(person), secondary_email: secMail },
          bmapi
        ),
        person.id
      )
      .then(() => {
        notifySuccess(intl.formatMessage(account.saveConfirm));
        setEdit(false);
        return updatePartecipants();
      })
      .catch((e) => notifyError(getErrorMessageString(e, intl)))
      .finally(() => setSaving(false));
  };

  const getCheckinTime = (checkin) =>
    `${format(new Date(checkin.check_in_at), "HH:mm")}${
      checkin.checked_out
        ? ` → ${format(new Date(checkin.check_out_at), "HH:mm")}`
        : ""
    }`;

  const status =
    checkins.length === 0
      ? USER_EVENT_STATUS.ABSENT
      : checkins.some((c) => !c.checked_out)
      ? USER_EVENT_STATUS.PRESENT
      : USER_EVENT_STATUS.OUT;

  const byDate = (a, b) => new Date(a.check_in_at) - new Date(b.check_in_at);

  const timeText =
    status === USER_EVENT_STATUS.ABSENT
      ? intl.formatMessage(common.notPresent)
      : checkins.sort(byDate).map(getCheckinTime).join(" | ");

  return (
    <ListItem>
      <Dialog
        open={edit}
        onClose={() => setEdit(false)}
        fullWidth
        maxWidth="sm"
      >
        <form onSubmit={handleEdit}>
          <DialogTitle>
            {intl.formatMessage({
              id: "component.campaignPerson.edit",
              defaultMessage: "Modifica iscritto",
            })}
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label={intl.formatMessage(common.secondaryEmail)}
              value={secMail}
              disabled={saving}
              type="email"
              onChange={(e) => setSecMail(e.target.value)}
              required
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setEdit(false)} disabled={saving}>
              {intl.formatMessage(common.cancel)}
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={saving}
            >
              {intl.formatMessage(common.save)}
            </Button>
          </DialogActions>
        </form>
        {saving && <LinearProgress />}
      </Dialog>

      {!!event && (
        <ListItemIcon>
          {status === USER_EVENT_STATUS.PRESENT ? (
            <Check style={{ color: "green" }} />
          ) : (
            <Clear />
          )}
        </ListItemIcon>
      )}
      <ListItemText
        primary={
          person.complete_name
            ? `${person.last_name} ${person.complete_name}`
            : person.email
        }
        secondary={
          event
            ? timeText
            : `${person.email} | ${intl.formatMessage({
                id: "component.campaignPerson.secondaryEmail",
                defaultMessage: "Referente",
              })}: ${
                person.secondary_email ||
                intl.formatMessage({
                  id: "component.campaignPerson.noSecondaryEmail",
                  defaultMessage: "Nessun contatto inserito",
                })
              }`
        }
      />
      {event ? (
        <ListItemSecondaryAction>
          <span style={{ display: "inline-block", position: "relative" }}>
            {status === USER_EVENT_STATUS.PRESENT ? (
              <IconButton disabled={loading} edge="end" onClick={doCheckout}>
                <ExitToApp />
              </IconButton>
            ) : (
              <IconButton disabled={loading} edge="end" onClick={doCheckin}>
                <AddBox />
              </IconButton>
            )}
            {loading && (
              <CircularProgress size={48} className={classes.fabProgress} />
            )}
          </span>
        </ListItemSecondaryAction>
      ) : bmapi.getUserInfo().role === ROLES.TENANT_MANAGER ? (
        <ListItemSecondaryAction>
          <IconButton edge="end" onClick={() => setEdit(true)}>
            <Edit />
          </IconButton>
        </ListItemSecondaryAction>
      ) : null}
    </ListItem>
  );
}

export default function Campaign() {
  const { campaignId } = useParams();
  const classes = styles.useStyles();
  const theme = useTheme();
  const intl = useIntl();
  const { campaigns, loadCampaigns } = useCampaigns();
  const history = useHistory();
  const { bmapi, startLoading, stopLoading, notifyError } = useBmapi();
  const [campaign, setCampaign] = useState(null);
  const [performance, setPerformance] = useState(false);
  const [terms, setTerms] = useState(false);
  const [event, setEvent] = useState(false);
  const [bg, setBg] = useState(false);
  const [code, setCode] = useState(false);
  const [startingEvent, setStartingEvent] = useState(false);
  const [stoppingEvent, setStoppingEvent] = useState(false);
  const [partecipants, setPartecipants] = useState([]);
  const [checkins, setCheckins] = useState([]);

  const viewEvent =
    campaign?.campaign_data.front_end_type === PRODUCTS.COURSE_PASS &&
    bmapi.can(FEATURES.EVENTS);

  useEffect(() => {
    loadCampaigns();
  }, [loadCampaigns]);

  const updatePartecipants = useCallback(() => {
    if (campaign?.campaign_id) {
      return bmapi.getCampaignUsers(campaign.campaign_id).then(setPartecipants);
    }
  }, [bmapi, campaign?.campaign_id]);

  useEffect(() => {
    updatePartecipants();
  }, [updatePartecipants]);

  const updateCheckins = useCallback(() => {
    if (event) return bmapi.getEventCheckIns(event.id).then(setCheckins);
    setCheckins([]);
  }, [bmapi, event]);

  useEffect(() => {
    updateCheckins();

    if (event) {
      const updateInterval = setInterval(updateCheckins, 10000);
      return () => clearInterval(updateInterval);
    }
  }, [event, updateCheckins]);

  useEffect(() => {
    if (campaigns) {
      setCampaign(campaigns.find((cv) => cv.campaign_id === campaignId));
    }
  }, [campaignId, campaigns]);

  const updateEvent = useCallback(() => {
    return bmapi
      .getCampaignEvents(campaign.campaign_id)
      .then((es) =>
        setEvent((es || []).find((e) => e.status === EVENT_STATUS.STARTED))
      );
  }, [bmapi, campaign?.campaign_id]);

  const update = useCallback(() => {
    if (campaign) {
      startLoading();

      setBg(campaign.image_url || bmapi.createBg(campaign.name));
      updateEvent();
      bmapi
        .getCampaignDetails(campaign.campaign_id)
        .then(({ use_terms, performance }) => {
          setPerformance(performance);
          setTerms(use_terms);
        })
        .catch((e) => notifyError(getErrorMessageString(e, intl)))
        .finally(stopLoading);
    }
  }, [
    bmapi,
    campaign,
    intl,
    notifyError,
    startLoading,
    stopLoading,
    updateEvent,
  ]);

  useEffect(() => {
    update();
  }, [update]);

  function hideCode() {
    setCode(false);
  }

  function loadCode(id) {
    startLoading();

    QRCodeGenerator.toDataURL(`${EVENT_PREFIX}${id}`, { scale: 16 })
      .then(setCode)
      .catch((e) => notifyError(getErrorMessageString(e, intl)))
      .finally(stopLoading);
  }

  const afterStart = () => {
    setStartingEvent(false);
    updateEvent();
  };

  const afterStop = () => {
    setStoppingEvent(false);
    updateEvent();
  };

  const goToCampaigns = () => {
    history.push(MANAGER_ROUTES.CAMPAIGNS.replace(":filter?/", ""));
  };

  const isPrize = useMemo(() => {
    if (campaigns && campaign) {
      const prizes = campaigns
        .map((c) => c.campaign_data.rules.prizes)
        .filter((p) => !!p)
        .flat();

      return prizes.some((p) => p.prize_id === campaign.campaign_id);
    }
  }, [campaign, campaigns]);

  return (
    !!campaign && (
      <Container maxWidth="sm">
        <CampaignEventStop
          campaign={campaign}
          event={event}
          open={stoppingEvent}
          onSuccess={afterStop}
          onCancel={() => setStoppingEvent(false)}
        />
        <CampaignEventStart
          campaign={campaign}
          open={startingEvent}
          onSuccess={afterStart}
          onCancel={() => setStartingEvent(false)}
        />

        <Dialog open={!!code} onClose={hideCode}>
          <DialogContent>
            {code && (
              <img
                src={code || ""}
                alt="QR Code"
                style={{ maxWidth: "100%" }}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={hideCode} color="primary" autoFocus>
              {intl.formatMessage(common.close)}
            </Button>
          </DialogActions>
        </Dialog>

        <Box my={2}>
          <Button onClick={goToCampaigns} startIcon={<ArrowBack />}>
            {intl.formatMessage(common.back)}
          </Button>
        </Box>

        <Box mb={4}>
          <Card style={{ position: "relative" }}>
            {campaign.demo && (
              <Ribbon label={intl.formatMessage(common.demo)} />
            )}
            {!!bg && <CardMedia className={classes.cardMedia} image={bg} />}
            <CardHeader
              title={
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {campaign.name}
                  <CampaignActions
                    campaign={campaign}
                    collapse={false}
                    events={[event]}
                    onUpdate={update}
                    isPrize={isPrize}
                    performance={performance}
                    filter={(item) =>
                      ![
                        CAMPAIGN_ACTION.LINK,
                        CAMPAIGN_ACTION.MOREINFO,
                      ].includes(item.id)
                    }
                  />
                </Box>
              }
              titleTypographyProps={{ variant: "h5" }}
              subheader={campaign.business_name}
            />
            <CardContent style={{ whiteSpace: "pre-line" }}>
              {campaign.description}
            </CardContent>
          </Card>
        </Box>

        {viewEvent && (
          <Box mb={4}>
            <Card>
              <CardContent>
                <Grid
                  container
                  spacing={1}
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography variant="h5">
                      {event
                        ? intl.formatMessage(common.eventRunning)
                        : intl.formatMessage(common.noEventRunning)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    {!!event && (
                      <Lens style={{ color: theme.palette.error.main }} />
                    )}
                  </Grid>
                </Grid>
              </CardContent>

              {event && (
                <CardContent>
                  <Typography>
                    {intl.formatMessage({
                      id: "campaign.time",
                      defaultMessage: "Orario",
                    })}
                    : {event.starting_time} → {event.ending_time}
                  </Typography>
                  <Typography>
                    {intl.formatMessage(common.location)}: {event.location_name}
                  </Typography>
                </CardContent>
              )}

              <CardActions disableSpacing>
                <Box
                  display="flex"
                  justifyItems="space-between"
                  flexWrap="wrap"
                >
                  {event ? (
                    <React.Fragment>
                      <Button
                        startIcon={<Stop />}
                        color="primary"
                        onClick={() => setStoppingEvent(true)}
                      >
                        {intl.formatMessage(common.endEvent)}
                      </Button>
                      <Button
                        startIcon={<Apps />}
                        color="primary"
                        onClick={() => loadCode(event.id)}
                      >
                        {intl.formatMessage(common.eventCode)}
                      </Button>
                    </React.Fragment>
                  ) : (
                    <Button
                      startIcon={<PlayArrow />}
                      color="primary"
                      onClick={() => setStartingEvent(true)}
                    >
                      {intl.formatMessage(common.startEvent)}
                    </Button>
                  )}
                  <Button
                    color="primary"
                    startIcon={<EventNote />}
                    component={RouterLink}
                    to={MANAGER_ROUTES.CAMPAIGN_EVENTS.replace(
                      ":campaignId",
                      campaignId
                    )}
                  >
                    {intl.formatMessage({
                      id: "campaign.eventsHistory",
                      defaultMessage: "Vedi tutti gli eventi",
                    })}
                  </Button>
                </Box>
              </CardActions>
            </Card>
          </Box>
        )}

        {viewEvent && (
          <Box mb={4}>
            <Card>
              <CardContent>
                <Typography variant="h5">
                  {intl.formatMessage({
                    id: "component.campaignEvents.enrolled",
                    defaultMessage: "Iscritti",
                  })}
                </Typography>
              </CardContent>
              {partecipants?.length ? (
                <List dense>
                  {partecipants.sort(byName).map((person) => (
                    <Partecipant
                      person={person}
                      event={event}
                      checkins={checkins.filter((c) => c.user_id === person.id)}
                      key={person.id}
                      updateCheckins={updateCheckins}
                      updatePartecipants={updatePartecipants}
                    />
                  ))}
                </List>
              ) : (
                <CardContent>
                  <Typography>
                    {intl.formatMessage({
                      id: "component.campaignEvents.noPartecipants",
                      defaultMessage: "Nessuno utente ancora iscritto",
                    })}
                  </Typography>
                </CardContent>
              )}
            </Card>
          </Box>
        )}

        <Box mb={4}>
          <Card>
            <CardContent>
              <CampaignPerformance
                campaign={campaign}
                owner={campaign.business_id === campaign.business_owner_id}
                performance={performance}
                terms={terms}
              />
            </CardContent>
          </Card>
        </Box>

        <Box my={2}>
          <Button onClick={goToCampaigns} startIcon={<ArrowBack />}>
            {intl.formatMessage(common.back)}
          </Button>
        </Box>
      </Container>
    )
  );
}
