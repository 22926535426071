import React from "react";
import { FormattedRelativeTime, useIntl } from "react-intl";

import { Box, Typography } from "@material-ui/core";
import { Info as InfoIcon } from "@material-ui/icons";

import ProductDetails from "./ProductDetails";
import { campaignPerformance, common } from "../messages";
import { PRODUCT_SUBTYPES, PRODUCT_TYPES } from "../utils/constants";

export default function CampaignPerformance({
  campaign,
  owner,
  performance,
  terms,
}) {
  const intl = useIntl();

  function getDetails(performance, terms, campaign, owner) {
    const data = {
      lastUsed:
        !performance || !performance.last_tx ? (
          false
        ) : performance.last_tx.id ? (
          <FormattedRelativeTime
            value={
              (new Date(performance.last_tx.created_at) - Date.now()) / 1000
            }
            numeric="auto"
            updateIntervalInSeconds={10}
          />
        ) : (
          "-"
        ),
      balance:
        (performance.issued_value -
          performance.used_value -
          performance.expired_value) /
        100,
      quantity:
        campaign.campaign_data.type === PRODUCT_TYPES.CAMPAIGN_COUPON &&
        campaign.campaign_data.waiting_list &&
        campaign.campaign_data.max_issue_number > 0
          ? performance.issued_qty - performance.used_qty
          : performance.issued_qty -
            performance.used_qty -
            performance.expired_qty,
      issued:
        campaign.campaign_data.type === PRODUCT_TYPES.CAMPAIGN_COUPON &&
        campaign.campaign_data.waiting_list &&
        campaign.campaign_data.max_issue_number > 0
          ? performance.issued_qty + performance.expired_qty
          : performance.issued_qty,
    };

    const perf = {
      plays: {
        label: campaignPerformance.plays,
        value: performance.used_qty,
      },
      issuedCards: {
        label: campaignPerformance.issuedCards,
        value: performance.issued_qty,
      },
      issuedPoints: {
        label: campaignPerformance.issuedPoints,
        value: performance.issued_value / 100,
      },
      balance: {
        label: campaignPerformance.balance,
        value: data.balance,
      },
      usedPoints: {
        label: campaignPerformance.usedPoints,
        value: performance.used_value / 100,
      },
      expiredPoints: {
        label: campaignPerformance.expiredPoints,
        value: performance.expired_value / 100,
      },
      participatingStores: {
        label: campaignPerformance.participatingStores,
        value: terms.length
          ? `${terms.filter((t) => t.signed).length}/${terms.length}`
          : intl.formatMessage(common.all),
      },
      remainingValue: {
        label: campaignPerformance.remainingValue,
        value: data.balance,
      },
      expiredValue: {
        label: campaignPerformance.expiredValue,
        value: performance.expired_value / 100,
      },
      usedValue: {
        label: campaignPerformance.usedValue,
        value: performance.used_value / 100,
      },
      lastUsedCoupon: {
        label: campaignPerformance.lastUsedCoupon,
        value: data.lastUsed,
      },
      lastUsedPass: {
        label: campaignPerformance.lastUsedPass,
        value: data.lastUsed,
      },
      usedCoupons: {
        label: campaignPerformance.usedCoupons,
        value: performance.used_qty,
      },
      availablesCoupons: {
        label: campaignPerformance.availablesCoupons,
        value: data.quantity,
      },
      issuedCoupons: {
        label: campaignPerformance.issuedCoupons,
        value: data.issued,
      },
      expiredCoupons: {
        label: campaignPerformance.expiredCoupons,
        value: performance.expired_qty,
      },
      usedPasses: {
        label: campaignPerformance.usedPasses,
        value: performance.used_qty,
      },
      availablesPasses: {
        label: campaignPerformance.availablesPasses,
        value: data.quantity,
      },
      issuedPasses: {
        label: campaignPerformance.issuedPasses,
        value: performance.issued_qty,
      },
      expiredPasses: {
        label: campaignPerformance.expiredPasses,
        value: performance.expired_qty,
      },
    };

    switch (campaign.type) {
      case PRODUCT_SUBTYPES.COUPON_MULTIWIN:
        return [perf.plays];

      case PRODUCT_SUBTYPES.EARNING_CARD_SIMPLE:
        return [
          perf.issuedCards,
          perf.issuedPoints,
          ...(owner ? [perf.balance, perf.usedPoints, perf.expiredPoints] : []),
          ...(owner && campaign.fromLoop ? [perf.participatingStores] : []),
        ];
      case PRODUCT_SUBTYPES.SHOPPING_CARD_SIMPLE:
      case PRODUCT_SUBTYPES.PROVISIONING_CARD:
        return [
          ...(owner
            ? [perf.issuedCards, perf.remainingValue, perf.expiredValue]
            : []),
          perf.usedValue,
          ...(owner && campaign.fromLoop ? [perf.participatingStores] : []),
        ];
      case PRODUCT_SUBTYPES.COUPON_DISCOUNT:
      case PRODUCT_SUBTYPES.COUPON_SIMPLE:
      case PRODUCT_SUBTYPES.COUPON_VALUE:
        return [
          perf.lastUsedCoupon,
          perf.usedCoupons,
          ...(owner
            ? [perf.availablesCoupons, perf.issuedCoupons, perf.expiredCoupons]
            : []),
          ...(owner && campaign.fromLoop ? [perf.participatingStores] : []),
        ];

      case PRODUCT_SUBTYPES.EVENT_PASS_SIMPLE:
        return [
          perf.lastUsedPass,
          perf.usedPasses,
          ...(owner
            ? [perf.availablesPasses, perf.issuedPasses, perf.expiredPasses]
            : []),
          ...(owner && campaign.fromLoop ? [perf.participatingStores] : []),
        ];

      default:
        break;
    }

    return [];
  }

  return (
    <React.Fragment>
      {performance && (
        <Box mt={1} mb={1}>
          {getDetails(performance, terms, campaign, owner).map((d) => (
            <ProductDetails
              key={intl.formatMessage(d.label)}
              label={intl.formatMessage(d.label)}
              value={d.value}
            />
          ))}
        </Box>
      )}

      {campaign.fromLoop ? (
        <Typography variant="body2" color="textSecondary" gutterBottom>
          <InfoIcon
            fontSize="small"
            color="inherit"
            style={{ verticalAlign: "text-bottom", marginRight: 4 }}
          />
          {owner
            ? intl.formatMessage(campaignPerformance.allStores)
            : intl.formatMessage(campaignPerformance.onlyMine)}
        </Typography>
      ) : null}
    </React.Fragment>
  );
}
