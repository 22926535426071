import { defineMessages } from "react-intl";

export const product = {
  coupon: defineMessages({
    allUsed: {
      id: "product.coupon.allUsed",
      defaultMessage: "Il coupon è stato già utilizzato",
    },
    confirmAccept: {
      id: "product.coupon.confirmAccept",
      defaultMessage:
        "Sei sicuro di voler ottenere il coupon della campagna {campaignName}?",
    },
    dateInfoIncoming: {
      id: "product.coupon.dateInfoIncoming",
      defaultMessage: `Potrai utilizzare il coupon a partire dal {start_date, date}`,
    },
    shared: {
      id: "product.coupon.shared",
      defaultMessage:
        "{counter} coupon {counter, plural, one {condiviso} other {condivisi}}",
    },
    get: {
      id: "product.coupon.getProduct",
      defaultMessage: "Ottieni il coupon",
    },
    issue: {
      id: "product.coupon.issue",
      defaultMessage: "Emetti coupon",
    },
    name: {
      id: "product.coupon.name",
      defaultMessage: "Coupon",
    },
    notAvailable: {
      id: "product.coupon.notAvailable",
      defaultMessage: "Il coupon non è disponibile",
    },
    notYetUsable: {
      id: "product.coupon.notYetUsable",
      defaultMessage: "Il coupon non è ancora utilizzabile",
    },
    showToManager: {
      id: "product.coupon.showToManager",
      defaultMessage:
        "Esponi questo QR Code all'addetto del punto vendita per utilizzare il coupon",
    },
    use: {
      id: "product.coupon.useProduct",
      defaultMessage: "Utilizza il coupon",
    },
    coverFor: {
      id: "product.coupon.coverFor",
      defaultMessage: "Immagine del coupon",
    },
    gracePeriodHelp: {
      id: "product.coupon.gracePeriodInfo",
      defaultMessage: "Giorni extra di validità del coupon oltre la scadenza",
    },
    iconFor: {
      id: "product.coupon.iconFor",
      defaultMessage: "Icona del coupon",
    },
    info: {
      id: "product.coupon.info",
      defaultMessage: "Informazioni del coupon",
    },
    maxIssueNumber: {
      id: "product.coupon.maxIssueNumber",
      defaultMessage: "Numero massimo di coupon disponibili",
    },
    maxProductsPerUser: {
      id: "product.coupon.maxProductsPerUser",
      defaultMessage: "Numero massimo di coupon per utente",
    },
    productLifespan: {
      id: "product.coupon.productLifespan",
      defaultMessage: "Validità coupon",
    },
    productLimitDate: {
      id: "product.coupon.productLimitDate",
      defaultMessage: "Data scadenza coupon",
    },
    received: {
      id: "product.coupon.received",
      defaultMessage: "Coupon ricevuto",
    },
    productValidityInfo: {
      id: "product.coupon.productValidityInfo",
      defaultMessage:
        "In caso di inserimento di scadenza e validità, alla generazione del coupon verrà calcolata e usata la data più vicina. Almeno uno dei 2 campi è obbligatorio",
    },
  }),
  pass: defineMessages({
    allUsed: {
      id: "product.pass.allUsed",
      defaultMessage: "Il pass è stato già utilizzato",
    },
    confirmAccept: {
      id: "product.pass.confirmAccept",
      defaultMessage:
        "Sei sicuro di voler ottenere il pass per l'evento {campaignName}?",
    },
    dateInfoIncoming: {
      id: "product.pass.dateInfoIncoming",
      defaultMessage: `Potrai utilizzare il pass a partire dal {start_date, date}`,
    },
    shared: {
      id: "product.pass.shared",
      defaultMessage:
        "{counter} pass {counter, plural, one {condiviso} other {condivisi}}",
    },
    get: {
      id: "product.pass.getProduct",
      defaultMessage: "Ottieni il pass",
    },
    issue: {
      id: "product.pass.issue",
      defaultMessage: "Emetti pass",
    },
    name: {
      id: "product.pass.name",
      defaultMessage: "Pass",
    },
    notAvailable: {
      id: "product.pass.notAvailable",
      defaultMessage: "Il pass non è disponibile",
    },
    notYetUsable: {
      id: "product.pass.notYetUsable",
      defaultMessage: "Il pass non è ancora utilizzabile",
    },
    showToManager: {
      id: "product.pass.showToManager",
      defaultMessage:
        "Esponi questo QR Code all'addetto del punto vendita per utilizzare il pass",
    },
    use: {
      id: "product.pass.useProduct",
      defaultMessage: "Utilizza il pass",
    },
    coverFor: {
      id: "product.pass.coverFor",
      defaultMessage: "Immagine del pass",
    },
    gracePeriodHelp: {
      id: "product.pass.gracePeriodInfo",
      defaultMessage: "Giorni extra di validità del pass oltre la scadenza",
    },
    iconFor: {
      id: "product.pass.iconFor",
      defaultMessage: "Icona del pass",
    },
    info: {
      id: "product.pass.info",
      defaultMessage: "Informazioni del pass",
    },
    maxIssueNumber: {
      id: "product.pass.maxIssueNumber",
      defaultMessage: "Numero massimo di pass disponibili",
    },
    maxProductsPerUser: {
      id: "product.pass.maxProductsPerUser",
      defaultMessage: "Numero massimo di pass per utente",
    },
    productLifespan: {
      id: "product.pass.productLifespan",
      defaultMessage: "Validità pass",
    },
    productLimitDate: {
      id: "product.pass.productLimitDate",
      defaultMessage: "Data scadenza pass",
    },
    received: {
      id: "product.pass.received",
      defaultMessage: "Pass ricevuto",
    },
    productValidityInfo: {
      id: "product.pass.productValidityInfo",
      defaultMessage:
        "In caso di inserimento di scadenza e validità, alla generazione dal pass verrà calcolata e usata la data più vicina. Almeno uno dei 2 campi è obbligatorio",
    },
  }),
  card: defineMessages({
    allUsed: {
      id: "product.card.allUsed",
      defaultMessage: "La card è stata già utilizzata",
    },
    confirmAccept: {
      id: "product.card.confirmAccept",
      defaultMessage:
        "Sei sicuro di voler ottenere la card per la campagna {campaignName}?",
    },
    dateInfoIncoming: {
      id: "product.card.dateInfoIncoming",
      defaultMessage: `Potrai utilizzare la card a partire dal {start_date, date}`,
    },
    shared: {
      id: "product.card.shared",
      defaultMessage:
        "{counter} card {counter, plural, one {condivisa} other {condivise}}",
    },
    get: {
      id: "product.card.getProduct",
      defaultMessage: "Ottieni la card",
    },
    issue: {
      id: "product.card.issue",
      defaultMessage: "Emetti card",
    },
    name: {
      id: "product.card.name",
      defaultMessage: "Card",
    },
    notAvailable: {
      id: "product.card.notAvailable",
      defaultMessage: "La card non è disponibile",
    },
    notYetUsable: {
      id: "product.card.notYetUsable",
      defaultMessage: "La card non è ancora utilizzabile",
    },
    showToManager: {
      id: "product.card.showToManager",
      defaultMessage:
        "Esponi questo QR Code all'addetto del punto vendita per utilizzare la card",
    },
    use: {
      id: "product.card.useProduct",
      defaultMessage: "Utilizza la card",
    },
    coverFor: {
      id: "product.card.coverFor",
      defaultMessage: "Immagine della card",
    },
    gracePeriodHelp: {
      id: "product.card.gracePeriodInfo",
      defaultMessage: "Giorni extra di validità della card oltre la scadenza",
    },
    iconFor: {
      id: "product.card.iconFor",
      defaultMessage: "Icona della card",
    },
    info: {
      id: "product.card.info",
      defaultMessage: "Informazioni della card",
    },
    maxIssueNumber: {
      id: "product.card.maxIssueNumber",
      defaultMessage: "Numero massimo di card disponibili",
    },
    maxProductsPerUser: {
      id: "product.card.maxProductsPerUser",
      defaultMessage: "Numero massimo di card per utente",
    },
    productLifespan: {
      id: "product.card.productLifespan",
      defaultMessage: "Validità card",
    },
    productLimitDate: {
      id: "product.card.productLimitDate",
      defaultMessage: "Data scadenza card",
    },
    received: {
      id: "product.card.received",
      defaultMessage: "Card ricevuta",
    },
    productValidityInfo: {
      id: "product.card.productValidityInfo",
      defaultMessage:
        "In caso di inserimento di scadenza e validità, alla generazione della card verrà calcolata e usata la data più vicina. Almeno uno dei 2 campi è obbligatorio",
    },
  }),
  subscription: defineMessages({
    allUsed: {
      id: "product.subscription.allUsed",
      defaultMessage: "L'abbonamento è stata già utilizzata",
    },
    confirmAccept: {
      id: "product.subscription.confirmAccept",
      defaultMessage:
        "Sei sicuro di voler ottenere l'abbonamento per la campagna {campaignName}?",
    },
    dateInfoIncoming: {
      id: "product.subscription.dateInfoIncoming",
      defaultMessage: `Potrai utilizzare l'abbonamento a partire dal {start_date, date}`,
    },
    shared: {
      id: "product.subscription.shared",
      defaultMessage:
        "{counter} abbonamento {counter, plural, one {condiviso} other {condivisi}}",
    },
    get: {
      id: "product.subscription.getProduct",
      defaultMessage: "Ottieni l'abbonamento",
    },
    issue: {
      id: "product.subscription.issue",
      defaultMessage: "Emetti l'abbonamento",
    },
    name: {
      id: "product.subscription.name",
      defaultMessage: "Abbonamento",
    },
    notAvailable: {
      id: "product.subscription.notAvailable",
      defaultMessage: "L'abbonamento non è disponibile",
    },
    notYetUsable: {
      id: "product.subscription.notYetUsable",
      defaultMessage: "L'abbonamento non è ancora utilizzabile",
    },
    showToManager: {
      id: "product.subscription.showToManager",
      defaultMessage:
        "Esponi questo QR Code all'addetto del punto vendita per utilizzare l'abbonamento",
    },
    use: {
      id: "product.subscription.useProduct",
      defaultMessage: "Utilizza l'abbonamento",
    },
    coverFor: {
      id: "product.subscription.coverFor",
      defaultMessage: "Immagine dell'abbonamento",
    },
    gracePeriodHelp: {
      id: "product.subscription.gracePeriodInfo",
      defaultMessage:
        "Giorni extra di validità dell'abbonamento oltre la scadenza",
    },
    iconFor: {
      id: "product.subscription.iconFor",
      defaultMessage: "Icona dell'abbonamento",
    },
    info: {
      id: "product.subscription.info",
      defaultMessage: "Informazioni dell'abbonamento",
    },
    maxIssueNumber: {
      id: "product.subscription.maxIssueNumber",
      defaultMessage: "Numero massimo di abbonamenti disponibili",
    },
    maxProductsPerUser: {
      id: "product.subscription.maxProductsPerUser",
      defaultMessage: "Numero massimo di abbonamenti per utente",
    },
    productLifespan: {
      id: "product.subscription.productLifespan",
      defaultMessage: "Validità abbonamento",
    },
    productLimitDate: {
      id: "product.subscription.productLimitDate",
      defaultMessage: "Data scadenza abbonamento",
    },
    received: {
      id: "product.subscription.received",
      defaultMessage: "Abbonamento ricevuto",
    },
    productValidityInfo: {
      id: "product.subscription.productValidityInfo",
      defaultMessage:
        "In caso di inserimento di scadenza e validità, alla generazione dell'abbonamento verrà calcolata e usata la data più vicina. Almeno uno dei 2 campi è obbligatorio",
    },
  }),
};
