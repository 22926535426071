import React, { useState, useEffect, useCallback } from "react";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";

import {
  Button,
  Card,
  CardContent,
  FormControl,
  InputAdornment,
  List,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  AccountBalanceWallet as AccountBalanceWalletIcon,
  Adjust as AdjustIcon,
  CardGiftcard as CardGiftcardIcon,
  Person as PersonIcon,
  TrendingUp as TrendingUpIcon,
} from "@material-ui/icons";
import { Alert } from "@material-ui/lab";

import ListItemInfo from "../ListItemInfo";
import NumberInput from "../NumberInput";
import { useBmapi } from "../../utils/bmapi-context";
import { POSITIVE_FLOATING_NUMBER, VARIANTS } from "../../utils/constants";
import { getErrorMessageString } from "../../utils/errors";
import { campaign, common, notifications } from "../../messages";

export default function ShoppingCardActionForm({ info, qrCode, rules }) {
  const intl = useIntl();
  const { bmapi, notifyError, startLoading, stopLoading } = useBmapi();
  const [isValid, setIsValid] = useState(true);
  const [saving, setSaving] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [values, setValues] = useState({ expense: "" });

  const getExpense = useCallback(
    () =>
      !values.expense.match(POSITIVE_FLOATING_NUMBER)
        ? 0
        : Math.round(values.expense * 100),
    [values.expense]
  );

  const validateInput = useCallback(
    (exp) =>
      rules &&
      info &&
      exp > 0 &&
      exp >= Math.min(rules.min_expense, info.available_value) &&
      exp <= info.available_value,
    [rules, info]
  );

  const getError = useCallback(
    (exp) => {
      if (!rules || !info) {
        return "";
      }
      if (exp > info.available_value) {
        return intl.formatMessage(notifications.noCredit);
      }
      if (exp > 0 && exp < Math.min(rules.min_expense, info.available_value)) {
        return intl.formatMessage(notifications.thresoldNotReached);
      }
    },
    [info, intl, rules]
  );

  useEffect(() => {
    if (rules && info) {
      setValues({
        expense: `${Math.min(rules.min_expense, info.available_value) || ""}`,
      });
    }
  }, [rules, info]);

  useEffect(() => {
    setIsValid(validateInput(getExpense()));
  }, [values.expense, getExpense, validateInput]);

  function handleChange(valueLabel) {
    return (event) => {
      setValues({ ...values, [valueLabel]: event.target.value });
    };
  }

  function onSubmit(event) {
    event.preventDefault();
    if (saving) return;
    if (
      rules.min_expense > getExpense() &&
      info.available_value > rules.min_expense
    ) {
      return notifyError(
        intl.formatMessage(notifications.thresoldNotRespected)
      );
    }

    setSaving(true);
    startLoading();

    bmapi
      .decreaseCardBalance(qrCode, getExpense())
      .then(() => setSuccess(true))
      .catch((e) => setError(getErrorMessageString(e, intl)))
      .finally(stopLoading);
  }

  function getNewCardBalance() {
    return info.available_value - getExpense();
  }

  const options = false;

  return (
    <Card>
      <CardContent>
        <form onSubmit={onSubmit}>
          <List>
            <ListItemInfo
              Icon={CardGiftcardIcon}
              label={intl.formatMessage(common.product)}
              text={intl.formatMessage(campaign.giftcard)}
            />
            <ListItemInfo
              Icon={AdjustIcon}
              label={intl.formatMessage(common.campaign)}
              text={info.campaign_name}
            />
            <ListItemInfo
              Icon={PersonIcon}
              label={intl.formatMessage({
                id: "component.actionForm.issuedTo",
                defaultMessage: "Emessa a",
              })}
              text={info && info.email}
            />
            <ListItemInfo
              Icon={AccountBalanceWalletIcon}
              label={intl.formatMessage({
                id: "component.actionForm.currentPointsLabel",
                defaultMessage: "Saldo disponibile",
              })}
              text={
                <Typography color="primary">
                  <FormattedNumber
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                    value={info.available_value / 100}
                  />{" "}
                  {info.currency}
                </Typography>
              }
            />
            <ListItemInfo>
              {options ? (
                <TextField
                  name="expense"
                  label={intl.formatMessage({
                    id: "component.shoppingCardActionForm.expense",
                    defaultMessage: "Importo utilizzato",
                  })}
                  value={values.expense}
                  onChange={handleChange("expense")}
                  select
                  fullWidth
                  variant="filled"
                  id="expense"
                  required
                  autoFocus
                  margin="normal"
                  disabled={saving}
                >
                  {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              ) : (
                <NumberInput
                  label={intl.formatMessage({
                    id: "component.shoppingCardActionForm.expense",
                    defaultMessage: "Importo utilizzato",
                  })}
                  fullWidth
                  variant="filled"
                  value={values.expense}
                  required
                  onChange={handleChange("expense")}
                  id="expense"
                  name="expense"
                  disabled={saving}
                  autoFocus
                  cents
                  error={!isValid}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {info.currency}
                      </InputAdornment>
                    ),
                  }}
                  helperText={
                    rules && rules.min_expense > 0 ? (
                      <FormattedMessage
                        id="component.shoppingCardActionForm.minExpenseWarning"
                        defaultMessage="Attenzione, le regole della GiftCard scansionata hanno impostato una spesa minima di {min} {currency}"
                        values={{
                          min: intl.formatNumber(rules.min_expense / 100, {
                            maximumFractionDigits: 2,
                          }),
                          currency: info.currency,
                        }}
                      />
                    ) : (
                      getError(getExpense())
                    )
                  }
                />
              )}
            </ListItemInfo>
            <ListItemInfo
              Icon={TrendingUpIcon}
              label={intl.formatMessage({
                id: "component.shoppingCardActionForm.total",
                defaultMessage: "Saldo dopo utilizzo",
              })}
              text={
                <Typography color="primary">
                  <FormattedNumber
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                    value={getNewCardBalance() / 100}
                  />{" "}
                  {info.currency}
                </Typography>
              }
            />
          </List>

          <FormControl fullWidth margin="normal">
            {!success && !error ? (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={!isValid}
              >
                {intl.formatMessage(common.confirm)}
              </Button>
            ) : (
              <Alert
                variant="filled"
                severity={success ? VARIANTS.SUCCESS : VARIANTS.ERROR}
              >
                {success ? (
                  <FormattedMessage
                    id="component.actionForm.transactionOk"
                    defaultMessage="Transazione effettuata"
                  />
                ) : (
                  error
                )}
              </Alert>
            )}
          </FormControl>
        </form>
      </CardContent>
    </Card>
  );
}
