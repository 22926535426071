import React from "react";
import { useIntl } from "react-intl";

import NumberInput from "../../NumberInput";
import { PRODUCT_TYPES } from "../../../utils/constants";
import { form } from "../../../messages";

export default function Rate({ values, handleChange, campaign }) {
  const intl = useIntl();

  return (
    [PRODUCT_TYPES.CAMPAIGN_EARNING_CARD].includes(values.type) && (
      <NumberInput
        name="rate"
        label={intl.formatMessage(form.rate)}
        placeholder="1"
        value={values.rate}
        cents
        onChange={handleChange("rate")}
        fullWidth
        margin="normal"
        helperText={intl.formatMessage(form.rateHelp)}
        disabled={!!campaign}
      />
    )
  );
}
