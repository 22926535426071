import React, { useState, useCallback, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import QrReader from "react-qr-reader";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  TextField,
  InputAdornment,
  IconButton,
  MenuItem,
} from "@material-ui/core";
import {
  CenterFocusStrong as CenterFocusIcon,
  Loyalty as LoyaltyIcon,
} from "@material-ui/icons";

import ListItemInfo from "./ListItemInfo";
import NumberInput from "./NumberInput";

import { useBmapi } from "../utils/bmapi-context";
import {
  ERRORS,
  FEATURES,
  PRODUCT_SUBTYPES,
  ROLES,
  USER_PREFIX,
  UUID_REGEX,
} from "../utils/constants";
import { getErrorMessageString } from "../utils/errors";
import { common, form, navigation, notifications, roles } from "../messages";

export default function IssueProduct({ campaign, open, onClose }) {
  const {
    bmapi,
    notifySuccess,
    notifyError,
    startLoading,
    stopLoading,
  } = useBmapi();
  const intl = useIntl();
  const [email, setEmail] = useState("");
  const [manager, setManager] = useState("");
  const [permissions, setPermissions] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [notification, setNotification] = useState(0);
  const [scanning, setScanning] = useState(false);
  const [value, setValue] = useState(
    campaign.campaign_data.rules.value
      ? campaign.campaign_data.rules.value / 100
      : ""
  );

  const handleClose = useCallback(
    (e) => {
      if (e) e.stopPropagation();
      setEmail("");
      setManager("");
      setQuantity(1);
      setScanning(false);
      setValue("");
      onClose();
    },
    [onClose]
  );

  const loadUser = useCallback(
    (userId) => {
      startLoading();

      bmapi
        .getUser(userId)
        .then((user) => {
          setScanning(false);
          setEmail(user.email);
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
          handleClose();
        })
        .finally(stopLoading);
    },
    [bmapi, handleClose, intl, notifyError, startLoading, stopLoading]
  );

  const handleScan = useCallback(
    (code) => {
      if (code && code.startsWith(USER_PREFIX)) {
        startLoading();
        loadUser(code.replace(USER_PREFIX, ""));
      } else if (code && UUID_REGEX.test(code)) {
        notifyError(
          intl.formatMessage(notifications.wrongQrCode, {
            buttonName: `'${intl.formatMessage(navigation.qrcode)}'`,
          })
        );
      }
    },
    [intl, loadUser, notifyError, startLoading]
  );

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleSend = useCallback(
    (event) => {
      event.preventDefault();
      startLoading();
      bmapi
        .issueCampaign(campaign.id, {
          user: email,
          quantity: Math.round(quantity),
          value: Math.round(value * 100),
          manager_id: manager,
          recharge_card:
            [PRODUCT_SUBTYPES.PROVISIONING_CARD].includes(campaign.type) &&
            campaign.campaign_data.rules.max_products_per_user === 1,
        })
        .then(() => {
          handleClose();
          notifySuccess(
            intl.formatMessage(notifications.campaignSent, {
              name: campaign.name,
            })
          );
        })
        .catch((e) =>
          e.code === ERRORS.NOT_FOUND
            ? notifyError(intl.formatMessage(notifications.emailNotFound))
            : notifyError(getErrorMessageString(e, intl))
        )
        .finally(stopLoading);
    },
    [
      bmapi,
      campaign.campaign_data.rules.max_products_per_user,
      campaign.id,
      campaign.name,
      campaign.type,
      email,
      handleClose,
      intl,
      manager,
      notifyError,
      notifySuccess,
      quantity,
      startLoading,
      stopLoading,
      value,
    ]
  );

  useEffect(() => {
    if ([PRODUCT_SUBTYPES.PROVISIONING_CARD].includes(campaign.type)) {
      bmapi
        .getTenantPermissions()
        .then((ps) =>
          setPermissions([
            ...new Set(
              ps.filter((p) => p.resource_id === campaign.business_id)
            ),
          ])
        )
        .catch(() => setPermissions([]));
    }
  }, [bmapi, campaign.business_id, campaign.type]);

  return (
    <Dialog onClose={handleClose} open={open} maxWidth={"sm"} fullWidth>
      {open &&
        (scanning ? (
          <React.Fragment>
            <DialogTitle>
              <FormattedMessage
                id="component.issueProduct.scannerDialogTitle"
                defaultMessage="Scansiona il codice utente"
              />
            </DialogTitle>
            <DialogContent>
              <QrReader
                onError={notifyError}
                onScan={handleScan}
                style={{ maxWidth: "100%", width: "100vw" }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setScanning(false)} color="primary">
                {intl.formatMessage(common.back)}
              </Button>
            </DialogActions>
          </React.Fragment>
        ) : (
          <form onSubmit={handleSend}>
            <DialogTitle>
              <FormattedMessage
                id="component.issueProduct.sendConfirmTitle"
                defaultMessage="Conferma invio"
              />
            </DialogTitle>
            <DialogContent>
              <List>
                <ListItemInfo
                  Icon={LoyaltyIcon}
                  label={intl.formatMessage(common.campaign)}
                  text={campaign.name}
                />
                <ListItemInfo>
                  <TextField
                    label={intl.formatMessage(common.email)}
                    fullWidth
                    variant="filled"
                    value={email}
                    onChange={handleChangeEmail}
                    type="email"
                    required
                    autoFocus
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setScanning(true)}
                            onMouseDown={(event) => event.preventDefault()}
                          >
                            <CenterFocusIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </ListItemInfo>
                {campaign.campaign_data.rules.max_products_per_user !== 1 && (
                  <ListItemInfo>
                    <NumberInput
                      label={intl.formatMessage(common.quantity)}
                      fullWidth
                      variant="filled"
                      value={quantity}
                      required
                      onChange={(e) => setQuantity(e.target.value)}
                      min={1}
                      max={
                        campaign.campaign_data.rules.max_products_per_user || 50
                      }
                    />
                  </ListItemInfo>
                )}
                {[
                  PRODUCT_SUBTYPES.SHOPPING_CARD_SIMPLE,
                  PRODUCT_SUBTYPES.PROVISIONING_CARD,
                  PRODUCT_SUBTYPES.COUPON_DISCOUNT,
                  PRODUCT_SUBTYPES.COUPON_VALUE,
                ].includes(campaign.type) &&
                  (campaign.campaign_data.rules.dynamic_value ? (
                    <ListItemInfo>
                      <NumberInput
                        label={intl.formatMessage(common.value)}
                        fullWidth
                        variant="filled"
                        value={value}
                        required
                        onChange={(e) => setValue(e.target.value)}
                        min={1}
                        cents
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {campaign.campaign_data.rules.currency}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </ListItemInfo>
                  ) : (
                    <ListItemInfo
                      label={intl.formatMessage(common.value)}
                      text={`${value} ${campaign.campaign_data.rules.currency}`}
                    />
                  ))}
                {bmapi.can(FEATURES.ISSUE_NOTIFICATION) &&
                  bmapi.getUserInfo().role === ROLES.TENANT_MANAGER && (
                    <ListItemInfo>
                      <TextField
                        label={intl.formatMessage(common.issueNotification)}
                        fullWidth
                        variant="filled"
                        value={notification}
                        required
                        onChange={(e) => setNotification(e.target.value)}
                        select
                      >
                        {[
                          {
                            value: 0,
                            label: {
                              id: "component.issueProduct.notificationNone",
                              defaultMessage: "Nessuna",
                            },
                          },
                          {
                            value: 1,
                            label: {
                              id: "component.issueProduct.notificationNormal",
                              defaultMessage: "Normale",
                            },
                          },
                          {
                            value: 2,
                            label: {
                              id: "component.issueProduct.notificationPrize",
                              defaultMessage: "Premio",
                            },
                          },
                        ].map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {intl.formatMessage(option.label)}
                          </MenuItem>
                        ))}
                      </TextField>
                    </ListItemInfo>
                  )}
                {campaign.campaign_data.rules.manager_restriction && (
                  <ListItemInfo>
                    <TextField
                      label={intl.formatMessage(roles.manager)}
                      fullWidth
                      onChange={(e) => setManager(e.target.value)}
                      variant="filled"
                      value={manager}
                      required
                      select
                    >
                      <MenuItem value="">
                        {intl.formatMessage(form.none)}
                      </MenuItem>
                      {permissions.map((perm) => (
                        <MenuItem key={perm.email} value={perm.owner_id}>
                          {perm.email}
                        </MenuItem>
                      ))}
                    </TextField>
                  </ListItemInfo>
                )}
              </List>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                {intl.formatMessage(common.cancel)}
              </Button>
              <Button type="submit" color="primary" variant="contained">
                {intl.formatMessage(common.send)}
              </Button>
            </DialogActions>
          </form>
        ))}
    </Dialog>
  );
}
