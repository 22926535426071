import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";

import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import {
  Add,
  Adjust,
  CenterFocusStrong,
  DoneAll,
  Loyalty,
  PlayArrow,
  Schedule,
} from "@material-ui/icons";

import CreateCampaignButton from "../../ui/CreateCampaignButton";
import FloatingActions, { Action } from "../../ui/FloatingActions";
import Title from "../../ui/Title";
import { useBmapi } from "../../utils/bmapi-context";
import { useCampaigns } from "../../utils/campaigns";
import {
  FEATURES,
  MANAGER_ROUTES,
  PRODUCT_TYPES,
  ROLES,
} from "../../utils/constants";
import { campaign, navigation } from "../../messages";
import styles from "../../utils/styles";

const SimpleBarChart = ({ data, dataMax }) => {
  return (
    <ResponsiveContainer width="100%" height={data.length * 40}>
      <BarChart data={data} layout="vertical">
        <YAxis
          dataKey="name"
          type="category"
          axisLine={false}
          tickLine={false}
          width={120}
        />
        <XAxis type="number" hide={true} domain={[0, dataMax || "dataMax"]} />
        <Bar dataKey="value" stackId="a">
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.fill} />
          ))}
        </Bar>
        <Bar dataKey="loop" stackId="a">
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.fillLoop} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default function Dashboard() {
  const { bmapi, businessId, toggleLoading } = useBmapi();
  const intl = useIntl();
  const history = useHistory();
  const { campaigns, loadCampaigns } = useCampaigns();
  const classes = styles.useStyles();
  const [stats, setStats] = useState();
  const [filter, setFilter] = useState("active");

  useEffect(() => {
    const activeCampaigns = (c) => c.isActive;
    const futureCampaigns = (c) => c.isFuture;
    const availableCampaigns = (c) => c.isAvailable;
    const expiredCampaigns = (c) => c.isExpired;

    toggleLoading(!campaigns);
    if (campaigns) {
      const emptyStat = () => ({
        total: 0,
        loop: 0,
        types: {
          ...(bmapi.can(FEATURES.CAMPAIGN_COUPON)
            ? { [PRODUCT_TYPES.CAMPAIGN_COUPON]: { total: 0, loop: 0 } }
            : {}),
          ...(bmapi.can(FEATURES.CAMPAIGN_EVENT_PASS)
            ? { [PRODUCT_TYPES.CAMPAIGN_EVENT_PASS]: { total: 0, loop: 0 } }
            : {}),
          ...(bmapi.can(FEATURES.CAMPAIGN_EARNING_CARD)
            ? { [PRODUCT_TYPES.CAMPAIGN_EARNING_CARD]: { total: 0, loop: 0 } }
            : {}),
          ...(bmapi.can(FEATURES.CAMPAIGN_MULTIWIN)
            ? { [PRODUCT_TYPES.CAMPAIGN_MULTIWIN]: { total: 0, loop: 0 } }
            : {}),
          ...(bmapi.can(FEATURES.CAMPAIGN_SHOPPING_CARD) ||
          bmapi.can(FEATURES.CAMPAIGN_PROVISIONING_CARD)
            ? { [PRODUCT_TYPES.CAMPAIGN_SHOPPING_CARD]: { total: 0, loop: 0 } }
            : {}),
        },
      });

      const statsByType = campaigns.reduce(
        (stats, c) => {
          const newStats = (key, extra = {}) => ({
            ...stats,
            [key]: {
              total: ++stats[key].total,
              loop: stats[key].loop + Number(c.loop_campaign),
              types: {
                ...stats[key].types,
                [c.campaign_data.type]: {
                  total:
                    (stats[key].types[c.campaign_data.type]?.total || 0) + 1,
                  loop:
                    (stats[key].types[c.campaign_data.type]?.loop || 0) +
                    +Number(c.loop_campaign),
                },
              },
              ...extra,
            },
          });

          if (futureCampaigns(c)) {
            return newStats("futureCampaigns", {
              startingNext:
                !stats.futureCampaigns.startingNext ||
                stats.futureCampaigns.startingNext.start_date > c.start_date
                  ? c
                  : stats.futureCampaigns.startingNext,
            });
          }
          if (activeCampaigns(c)) {
            return newStats("activeCampaigns", {
              expiringNext:
                !stats.activeCampaigns.expiringNext ||
                stats.activeCampaigns.expiringNext.expiration_date >
                  c.expiration_date
                  ? c
                  : stats.activeCampaigns.expiringNext,
            });
          }
          if (availableCampaigns(c)) {
            return newStats("availableCampaigns", {
              future:
                (stats.availableCampaigns.future || 0) + Number(c.isFuture),
              active:
                (stats.availableCampaigns.active || 0) + Number(c.isActive),
              expired:
                (stats.availableCampaigns.expired || 0) + Number(c.isExpired),
            });
          }
          if (expiredCampaigns(c)) {
            return newStats("expiredCampaigns", {
              lastExpired:
                !stats.expiredCampaigns.lastExpired ||
                stats.expiredCampaigns.lastExpired.expiration_date <
                  c.expiration_date
                  ? c
                  : stats.expiredCampaigns.lastExpired,
            });
          }
          return stats;
        },
        {
          futureCampaigns: emptyStat(),
          activeCampaigns: emptyStat(),
          availableCampaigns: emptyStat(),
          expiredCampaigns: emptyStat(),
        }
      );

      const getTotals = (types) => Object.values(types).map((v) => v.total);
      const maxValue = Math.max(
        ...getTotals(statsByType.futureCampaigns.types),
        ...getTotals(statsByType.activeCampaigns.types),
        ...getTotals(statsByType.availableCampaigns.types),
        ...getTotals(statsByType.expiredCampaigns.types)
      );

      setStats({
        ...statsByType,
        maxValue: maxValue && maxValue > 10 ? maxValue : 10,
      });
    }
  }, [bmapi, campaigns, toggleLoading]);

  useEffect(() => {
    loadCampaigns();
  }, [loadCampaigns]);

  const goTo = (filter) => () =>
    history.push(MANAGER_ROUTES.CAMPAIGNS.replace(":filter?", filter));

  const formatDate = (date) => (date ? intl.formatDate(new Date(date)) : "");

  const campaignMap = {
    CAMPAIGN_COUPON: {
      color: "#B71C1C",
      lightColor: "#C62828",
      friendlyName: intl.formatMessage(campaign.couponShort),
    },
    CAMPAIGN_EVENT_PASS: {
      color: "#F57F17",
      lightColor: "#F9A825",
      friendlyName: intl.formatMessage(campaign.eventPassShort),
    },
    CAMPAIGN_MULTIWIN: {
      color: "#FFEB3B",
      lightColor: "#FFF176",
      friendlyName: intl.formatMessage(campaign.multiwinShort),
    },
    CAMPAIGN_EARNING_CARD: {
      color: "#1B5E20",
      lightColor: "#2E7D32",
      friendlyName: intl.formatMessage(campaign.fidelityShort),
    },
    CAMPAIGN_SHOPPING_CARD: {
      color: "#0D47A1",
      lightColor: "#1565C0",
      friendlyName: intl.formatMessage(campaign.giftcardShort),
    },
  };

  const cards = [
    {
      enabled: filter === "active",
      stats: stats?.activeCampaigns,
      label: intl.formatMessage({
        id: "pages.dashboard.runningCampaigns",
        defaultMessage: "Campagne in corso",
      }),
      Icon: PlayArrow,
      text: !!stats?.activeCampaigns.expiringNext && (
        <React.Fragment>
          <Typography variant="body2">
            <FormattedMessage
              id="pages.dashboard.nextEndingCampaign"
              defaultMessage="Prossima campagna in scadenza:"
            />
          </Typography>
          <Typography variant="body2">
            <strong>{stats?.activeCampaigns.expiringNext.name}</strong> (
            {formatDate(stats?.activeCampaigns.expiringNext.expiration_date)})
          </Typography>
        </React.Fragment>
      ),
      cta: [
        {
          action: goTo("active"),
          color: "primary",
          Icon: Loyalty,
          label: intl.formatMessage(navigation.viewAllCampaigns),
        },
      ],
    },
    {
      enabled: filter === "next",
      stats: stats?.futureCampaigns,
      label: intl.formatMessage({
        id: "pages.dashboard.futureCampaigns",
        defaultMessage: "Prossime campagne",
      }),
      Icon: Schedule,
      text: !!stats?.futureCampaigns.startingNext && (
        <React.Fragment>
          <Typography variant="body2">
            <FormattedMessage
              id="pages.dashboard.nextStartingCampaign"
              defaultMessage="Prossima campagna a partire:"
            />
          </Typography>
          <Typography variant="body2">
            <strong>{stats?.futureCampaigns.startingNext.name}</strong> (
            {formatDate(stats?.futureCampaigns.startingNext.start_date)})
          </Typography>
        </React.Fragment>
      ),
      cta: [
        {
          action: goTo("next"),
          color: "primary",
          Icon: Loyalty,
          label: intl.formatMessage(navigation.viewAllCampaigns),
        },
      ],
    },
    {
      enabled: filter === "availables",
      stats: stats?.availableCampaigns,
      label: intl.formatMessage({
        id: "pages.dashboard.availableCampaigns",
        defaultMessage: "Campagne disponibili",
      }),
      Icon: Adjust,
      text: !!stats?.futureCampaigns.startingNext && (
        <React.Fragment>
          <Typography variant="body2">
            <FormattedMessage
              id="pages.dashboard.runningCampaigns"
              defaultMessage="Campagne in corso"
            />
            : <strong>{stats?.availableCampaigns.active || 0}</strong>
          </Typography>
          <Typography variant="body2">
            <FormattedMessage
              id="pages.dashboard.futureCampaigns"
              defaultMessage="Prossime campagne"
            />
            : <strong>{stats?.availableCampaigns.future || 0}</strong>
          </Typography>
        </React.Fragment>
      ),
      cta: [
        {
          action: goTo("availables"),
          color: "primary",
          Icon: Loyalty,
          label: intl.formatMessage(navigation.viewAllCampaigns),
        },
      ],
    },
    {
      enabled: filter === "expired",
      stats: stats?.expiredCampaigns,
      label: intl.formatMessage({
        id: "pages.dashboard.endedCampaigns",
        defaultMessage: "Campagne concluse",
      }),
      Icon: DoneAll,
      text: !!stats?.expiredCampaigns.lastExpired && (
        <React.Fragment>
          <Typography variant="body2">
            <FormattedMessage
              id="pages.dashboard.lastEndedCampaign"
              defaultMessage="Ultima campagna conclusa:"
            />
          </Typography>
          <Typography variant="body2">
            <strong>{stats?.expiredCampaigns.lastExpired.name}</strong> (
            {formatDate(stats?.expiredCampaigns.lastExpired.expiration_date)})
          </Typography>
        </React.Fragment>
      ),
      cta: [
        {
          action: goTo("expired"),
          color: "primary",
          Icon: Loyalty,
          label: intl.formatMessage(navigation.viewAllCampaigns),
        },
      ],
    },
  ];
  const currentCard = cards.find((i) => i.enabled);

  return !!campaigns && !!businessId ? (
    <Container maxWidth="sm">
      <Title>
        <FormattedMessage
          id="pages.dashboard.title"
          defaultMessage="Cruscotto"
        />
      </Title>

      {campaigns.length === 0 ? (
        bmapi.can(FEATURES.MANAGE_CAMPAIGN) ? (
          <CreateCampaignButton
            trigger={(onClick) => (
              <Button onClick={onClick} variant="contained">
                <FormattedMessage
                  id="component.campaigns.createFirst"
                  defaultMessage="Crea la tua prima campagna"
                />
              </Button>
            )}
          />
        ) : (
          <Typography gutterBottom>
            <FormattedMessage
              id="component.campaigns.noCampaign"
              defaultMessage="Nessuna campagna presente."
            />
          </Typography>
        )
      ) : (
        <React.Fragment>
          <Box mb={2}>
            <Tabs
              value={filter}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              onChange={(_, v) => setFilter(v)}
            >
              <Tab
                label={intl.formatMessage({
                  id: "common.actives",
                  defaultMessage: "Attive",
                })}
                value="active"
                style={{ minWidth: 0 }}
              />
              <Tab
                label={intl.formatMessage({
                  id: "common.next",
                  defaultMessage: "Prossime",
                })}
                value="next"
                style={{ minWidth: 0 }}
              />
              <Tab
                label={intl.formatMessage({
                  id: "common.availables",
                  defaultMessage: "Disponibili",
                })}
                value="availables"
                style={{ minWidth: 0 }}
              />
              <Tab
                label={intl.formatMessage({
                  id: "common.expired",
                  defaultMessage: "Concluse",
                })}
                value="expired"
                style={{ minWidth: 0 }}
              />
            </Tabs>
          </Box>

          <Card>
            <CardHeader
              avatar={
                <Avatar className={classes.statsIcon}>
                  <currentCard.Icon fontSize="large" />
                </Avatar>
              }
              classes={{ content: classes.statsTitle }}
              title={currentCard.label}
              titleTypographyProps={{ variant: "body1" }}
              subheader={currentCard.stats?.total}
              subheaderTypographyProps={{
                variant: "h4",
                style: { color: "inherit", fontWeight: "bold" },
              }}
            />
            <CardContent>
              {!!currentCard.stats && (
                <React.Fragment>
                  <Box mb={2}>
                    <SimpleBarChart
                      data={Object.keys(currentCard.stats.types).map((k) => ({
                        name: campaignMap[k].friendlyName,
                        value:
                          (currentCard.stats.types[k].total || 0.05) -
                          currentCard.stats.types[k].loop,
                        loop: currentCard.stats.types[k].loop || 0,
                        fill: campaignMap[k].color,
                        fillLoop: campaignMap[k].lightColor,
                      }))}
                      dataMax={stats.maxValue}
                    />
                  </Box>
                  {currentCard.text}
                </React.Fragment>
              )}
            </CardContent>
            <CardActions>
              {currentCard.cta.map((cta) => (
                <Button
                  key={cta.label}
                  color={cta.color}
                  startIcon={!!cta.Icon && <cta.Icon />}
                  onClick={cta.action}
                >
                  {cta.label}
                </Button>
              ))}
              {bmapi.can(FEATURES.MANAGE_CAMPAIGN) && (
                <CreateCampaignButton
                  trigger={(onClick) => (
                    <Button
                      color="primary"
                      startIcon={<Add />}
                      onClick={onClick}
                    >
                      {intl.formatMessage({
                        id: "page.campaigns.createCampaign",
                        defaultMessage: "Crea campagna",
                      })}
                    </Button>
                  )}
                />
              )}
            </CardActions>
          </Card>
        </React.Fragment>
      )}

      {bmapi.getUserInfo().role !== ROLES.TENANT_MANAGER &&
        bmapi.can(FEATURES.SCAN_QR) &&
        campaigns.some((c) => c.isActive) && (
          <FloatingActions>
            <Action
              icon={<CenterFocusStrong />}
              label={intl.formatMessage({
                id: "common.scan",
                defaultMessage: "Scansiona",
              })}
              action={() => history.push(MANAGER_ROUTES.SCAN)}
            />
          </FloatingActions>
        )}
    </Container>
  ) : !businessId && campaigns?.length === 0 ? (
    <Container maxWidth="md">
      <Typography variant="body1" gutterBottom>
        <FormattedMessage
          id="pages.dashboard.emptyMessage"
          defaultMessage="Non ci sono negozi associati al tuo indirizzo email."
        />
      </Typography>
      <Typography variant="body1" gutterBottom>
        <FormattedMessage
          id="pages.dashboard.emptySolution"
          defaultMessage="Contatta l'amministratore per risolvere il problema."
        />
      </Typography>
    </Container>
  ) : null;
}
