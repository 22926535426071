import React, { useCallback, useContext, useEffect, useState } from "react";

import BmarkenAPI from "./bmapi";
import {
  CONSUMER_NAMESPACE,
  ERRORS,
  MANAGER_NAMESPACE,
  SUBSCRIPTIONS,
  VARIANTS,
} from "./constants";
import { getLanguageWithoutRegionCode } from "./dictionaries";
import { setupMeta } from "./meta";
import pagesLoader from "./pages";

const getLanguage = (bmapi) => {
  if (bmapi) {
    const userLang = bmapi.userData?.language;
    const savedLang = bmapi.getTenantData().language;
    const browserLang = getLanguageWithoutRegionCode(navigator.language);
    const defaultLang = bmapi?.settings.defaultLanguage;

    return [userLang, savedLang, browserLang, defaultLang].find(
      (lang) => lang && bmapi.settings.languages.includes(lang)
    );
  }
};

const bmapiContext = React.createContext(null);

export const useBmapi = () => useContext(bmapiContext);

export const BmapiProvider = ({
  children,
  tenant,
  subscription,
  app,
  baseUrl,
}) => {
  const [bmapi, setBmapi] = useState(null);
  const [error, setError] = useState(null);
  const [starting, setStarting] = useState(true);
  const [language, setLanguage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(false);
  const [pages, setPages] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [user, setUser] = useState(null);

  const notifyError = useCallback((text) => {
    setShowMessage(true);
    setMessage({ text, type: VARIANTS.ERROR });
  }, []);
  const notifySuccess = useCallback((text) => {
    setShowMessage(true);
    setMessage({ text, type: VARIANTS.SUCCESS });
  }, []);
  const hideMessage = useCallback(() => setShowMessage(false), []);

  const startLoading = useCallback(() => setLoading(true), []);
  const stopLoading = useCallback(() => setLoading(false), []);
  const toggleLoading = useCallback(
    (flag) => setLoading((l) => (typeof flag === "boolean" ? flag : !l)),
    []
  );

  useEffect(() => {
    if (bmapi) {
      setPages(pagesLoader(bmapi, bmapi.app, bmapi.userStatus, baseUrl));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bmapi?.app, bmapi?.userStatus, user]);

  useEffect(() => {
    try {
      const [api, onLoad] = new BmarkenAPI(tenant, subscription, app);
      onLoad
        .then(() => {
          setStarting(false);
          setupMeta(api, baseUrl);
          if (
            SUBSCRIPTIONS &&
            api.subscription?.friendly_url &&
            api.subscription?.friendly_url !== subscription
          ) {
            window.history.replaceState(
              "",
              "",
              window.location.href.replace(
                api.subscription.subscription_key,
                api.subscription.friendly_url
              )
            );
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.message === ERRORS.MANAGER_ONLY) {
            window.location.href = `${baseUrl}${CONSUMER_NAMESPACE.slice(1)}`;
          } else if (
            error.message === ERRORS.WRONG_SUBSCRIPTION &&
            api.settings.subscriptionsGeneralURL
          ) {
            window.location.href = api.settings.subscriptionsGeneralURL;
          } else {
            console.error("Failed start", error);
            setError(error);
          }
        });
      setBmapi(api);

      return api.onAuthStateChanged((user) => {
        setUser(user);
        setLanguage(getLanguage(api));
        api.setLanguage(getLanguage(api));

        if (
          !user &&
          api.getTenantData().logout &&
          api.settings.customLogout &&
          api.isConsumer()
        ) {
          api.setTenantData({ logout: false });
          window.location.href = api.settings.customLogout.replace(
            "{cbUrl}",
            baseUrl
          );
        }
      });
    } catch (error) {
      if (error.message === ERRORS.ONLY_MANAGER_ENABLED) {
        window.location.href = `${baseUrl}${MANAGER_NAMESPACE.slice(1)}`;
      } else if (error.message === ERRORS.MISS_SUBSCRIPTION) {
        window.location.href = error.settings.subscriptionsGeneralURL;
      } else {
        console.error("Failed start", error);
        setError(error);
      }
    }
  }, [app, baseUrl, subscription, tenant]);

  const value = {
    baseUrl,
    bmapi,
    businesses: user?.businesses,
    businessId: user?.business?.id,
    error,
    hideMessage,
    language,
    loading,
    message,
    notifyError,
    notifySuccess,
    pages,
    showMessage,
    starting: user === null || starting,
    startLoading,
    stopLoading,
    tenant,
    toggleLoading,
    userData: bmapi?.userData,
    userId: user?.user_id,
  };

  return (
    <bmapiContext.Provider value={value}>{children}</bmapiContext.Provider>
  );
};
