import React, { useState, useEffect, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, Link as RouterLink } from "react-router-dom";

import { Box, Container, Link, Typography } from "@material-ui/core";
import { Apps, CenterFocusStrong } from "@material-ui/icons";

import Banners from "../../components/Banners";
import FloatingActions, { Action } from "../../ui/FloatingActions";
import ProductsList from "../../ui/ProductsList";
import Title from "../../ui/Title";
import { useBmapi } from "../../utils/bmapi-context";
import { CONSUMER_ROUTES, FEATURES, TENANTS } from "../../utils/constants";
import { getErrorMessageString } from "../../utils/errors";
import { common, navigation } from "../../messages";

export default function Products() {
  const { bmapi, startLoading, stopLoading, notifyError } = useBmapi();
  const intl = useIntl();
  const history = useHistory();
  const [stats, setStats] = useState(null);
  const [products, setProducts] = useState(null);
  const [firstLogin] = useState(bmapi.getUserData().firstLogin);

  const updateData = useCallback(() => {
    startLoading();
    const alpha = (a, b) => {
      if (!a.campaign.rules.main_event_id && b.campaign.rules.main_event_id)
        return -1;
      if (a.campaign.rules.main_event_id && !b.campaign.rules.main_event_id)
        return 1;
      return a.campaign.name.localeCompare(b.campaign.name);
    };
    const revAlpha = (b, a) => a.campaign.name.localeCompare(b.campaign.name);

    Promise.all([bmapi.getUserProductsStats(), bmapi.getUserProducts()])
      .then(([stats = [], products = []]) => {
        setStats(stats.sort(bmapi.tenant === TENANTS.LUCCA ? revAlpha : alpha));
        setProducts(products);
      })
      .catch((e) => notifyError(getErrorMessageString(e, intl)))
      .finally(stopLoading);
  }, [bmapi, intl, notifyError, startLoading, stopLoading]);

  useEffect(() => updateData(), [updateData]);

  const createLink = (str) => (
    <Link key={str} component={RouterLink} to={CONSUMER_ROUTES.QR_CODE}>
      {str}
    </Link>
  );

  useEffect(() => {
    bmapi.setUserData({ firstLogin: false });
  }, [bmapi]);

  return !stats || !products ? null : (
    <React.Fragment>
      {!!stats?.length && !!products?.length ? (
        <Container maxWidth="lg">
          {firstLogin && bmapi.settings.welcomeBanner && (
            <Box mb={2}>
              <Title>{intl.formatMessage(common.welcome)}</Title>
              {bmapi.can(FEATURES.MY_CODE) && (
                <Typography gutterBottom>
                  {intl.formatMessage(common.welcomeMsg, { link: createLink })}
                </Typography>
              )}
            </Box>
          )}
          <Banners products={products} />
          {!!stats.length && (
            <React.Fragment>
              <Title>
                <FormattedMessage
                  id="pages.products.title"
                  defaultMessage="I tuoi coupon/card/pass"
                />
              </Title>
              <ProductsList
                stats={stats}
                products={products}
                updateData={updateData}
              />
            </React.Fragment>
          )}
        </Container>
      ) : (
        bmapi.settings.welcomeMessage && (
          <Container maxWidth="lg">
            <Banners products={products} />

            <Title>{intl.formatMessage(common.welcome)}</Title>
            {bmapi.can(FEATURES.MY_CODE) && (
              <Typography gutterBottom>
                {intl.formatMessage(common.welcomeMsg, { link: createLink })}
              </Typography>
            )}
          </Container>
        )
      )}
      {bmapi.can(FEATURES.CHECKIN) && (
        <FloatingActions>
          <Action
            icon={<CenterFocusStrong />}
            label={intl.formatMessage(navigation.checkin)}
            action={() => history.push(CONSUMER_ROUTES.SCAN)}
          />
        </FloatingActions>
      )}

      {!bmapi.can(FEATURES.CHECKIN) && bmapi.can(FEATURES.MY_CODE) && (
        <FloatingActions>
          <Action
            icon={<Apps />}
            label={intl.formatMessage(navigation.qrcode)}
            action={() => history.push(CONSUMER_ROUTES.QR_CODE)}
          />
        </FloatingActions>
      )}

      {bmapi.can(FEATURES.SCAN_QR_CONSUMER) && (
        <FloatingActions>
          <Action
            icon={<CenterFocusStrong />}
            label={intl.formatMessage({
              id: "common.scan",
              defaultMessage: "Scansiona",
            })}
            action={() => {
              history.push(CONSUMER_ROUTES.SCAN);
            }}
          />
        </FloatingActions>
      )}
    </React.Fragment>
  );
}
