import React, { useState, useCallback, useEffect } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import { Container } from "@material-ui/core";

import { navigation } from "../../messages";
import QrScanner from "../../ui/QrScanner";
import Title from "../../ui/Title";
import { useBmapi } from "../../utils/bmapi-context";
import { CONSUMER_ROUTES } from "../../utils/constants";

import { getErrorMessageString } from "../../utils/errors";

export default function StampScanner() {
  const intl = useIntl();
  const history = useHistory();
  const { bmapi, notifyError, notifySuccess, stopLoading } = useBmapi();
  const [qrCode, setQrCode] = useState(null);

  const onError = useCallback(
    (error) => {
      stopLoading();
      notifyError(error);
    },
    [notifyError, stopLoading]
  );

  const getParamFromSearch = (search, key) =>
    decodeURIComponent(
      search.replace(
        new RegExp(
          "^(?:.*[&\\?]" +
            encodeURIComponent(key).replace(/[.+*]/g, "\\$&") +
            "(?:\\=([^&]*))?)?.*$",
          "i"
        ),
        "$1"
      )
    );

  const getParamFromURL = useCallback((url, key) => {
    const reURLInformation = new RegExp(
      [
        "^(https?:)//", // protocol
        "(([^:/?#]*)(?::([0-9]+))?)", // host (hostname and port)
        "(/{0,1}[^?#]*)", // pathname
        "(\\?[^#]*|)", // search
        "(#.*|)$", // hash
      ].join("")
    );
    const match = url.match(reURLInformation);
    if (!match) {
      return null;
    }
    /* 
    parsed URL
      {
        protocol: match[1],
        host: match[2],
        hostname: match[3],
        port: match[4],
        pathname: match[5],
        search: match[6],
        hash: match[7],
      }
    */
    return getParamFromSearch(match[6], key);
  }, []);

  const sendCode = useCallback(
    (stamp) => {
      const code = getParamFromURL(stamp, "qrcode");
      if (code) {
        bmapi
          .processStamp(code)
          .then((res) => {
            let msg = intl.formatMessage({
              id: "common.receivedPoints",
              defaultMessage: "Punti ricevuti",
            });
            if (res && res.points) {
              // DECIMALS
              msg += ": " + Math.round(res.points / 100);
            }
            notifySuccess(msg);
            history.push(CONSUMER_ROUTES.HOME);
          })
          .catch((e) => {
            onError(getErrorMessageString(e, intl));
            history.push(CONSUMER_ROUTES.HOME);
          });
      } else {
        onError(
          intl.formatMessage({
            id: "pages.scanner.invalidQrCode",
            defaultMessage: "Codice scannerizzato non valido",
          })
        );
        history.push(CONSUMER_ROUTES.HOME);
      }
    },
    [getParamFromURL, intl, notifySuccess, onError, bmapi, history]
  );

  useEffect(() => {
    if (qrCode) sendCode(qrCode);
  }, [qrCode, sendCode]);

  return (
    <Container maxWidth="sm">
      <Title>{intl.formatMessage(navigation.scanner)}</Title>
      <QrScanner
        handleCode={setQrCode}
        handleError={onError}
        acceptAll={true}
        excludeKeyboard={true}
      />
    </Container>
  );
}
