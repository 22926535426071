import React from "react";
import { useIntl } from "react-intl";

import { InputAdornment } from "@material-ui/core";

import NumberInput from "../../NumberInput";
import { PRODUCT_SUBTYPES } from "../../../utils/constants";
import { form } from "../../../messages";

export default function Value({ values, handleChange, campaign }) {
  const intl = useIntl();

  const overrides = {
    [PRODUCT_SUBTYPES.COUPON_DISCOUNT]: {
      label: intl.formatMessage(form.valueSingle),
      max: 100,
    },
    [PRODUCT_SUBTYPES.COUPON_SIMPLE]: {
      cents: true,
      label: intl.formatMessage(form.price),
      required: false,
    },
    [PRODUCT_SUBTYPES.COUPON_VALUE]: {
      label: intl.formatMessage(form.valueSingle),
      cents: true,
    },
    [PRODUCT_SUBTYPES.EARNING_CARD_SIMPLE]: {
      currency: intl.formatMessage(form.threshold),
      label: intl.formatMessage(form.initialValue),
      placeholder: "0",
      required: false,
    },
    [PRODUCT_SUBTYPES.EVENT_PASS_SIMPLE]: {
      cents: true,
      label: intl.formatMessage(form.price),
      required: false,
    },
    [PRODUCT_SUBTYPES.SHOPPING_CARD_SIMPLE]: {
      cents: true,
    },
    [PRODUCT_SUBTYPES.PROVISIONING_CARD]: {
      cents: true,
    },
  };

  return (
    <NumberInput
      name="value"
      label={intl.formatMessage(form.value)}
      value={values.value}
      onChange={handleChange("value")}
      fullWidth
      required={!values.dynamic_value && true}
      margin="normal"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {overrides[values.subtype].currency || values.currency}
          </InputAdornment>
        ),
      }}
      {...overrides[values.subtype]}
      disabled={!!campaign || values.dynamic_value}
    />
  );
}
